import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { isIOS } from "utils/utils";
import useAccidentForm from "hooks/useAccidentForm";
import { Answers, FormTypes, QUESTION_TYPE_IDS, QuestionObject } from "types/types";

import { MainField } from "../questionnaire/styles/questionnaireStyles";
import { ContentContainer } from "styles/generalStyles";
import {
  Content,
  Wrapper,
  WarningBar,
  CloseIconContainer,
} from "./styles/accidenFormStyles";
import useTimeout from "hooks/useTimeout";
import useContentOverflow from "hooks/useContentOverflow";
import usePhotosObjectStore from "hooks/usePhotosObjectStore";

import NoTypeWarning from "pages/questionnaire/components/NoTypeWarning";
import QuestionActions from "components/molecules/question-actions/QuestionActions";
import WebcamCapture from "components/molecules/webcam-capture/WebcamCapture";
import PhotosInAnswer from "pages/questionnaire/components/PhotosInAnswer";
import ReactionModal from "components/molecules/reaction-modal/ReactionModal";
import NavHeader from "./components/NavHeader";
import QuestionTitle from "./components/QuestionTitle";
import CloseIcon from "components/molecules/close-icon/CloseIcon";
import ReactionInAnswer from "pages/questionnaire/components/ReactionInAnswer";
import ConfirmationModal from "components/organisms/ConfirmationModal";
import ScrollButton from "pages/questionnaire/components/ScrollButton";
import PhotoPrompt from "components/molecules/action-prompts/PhotoPrompt";
import TextPrompt from "components/molecules/action-prompts/TextPrompt";
import { getMinMaxPhotos } from "pages/questionnaire/utils/utils";

const { NUMERIC, TEXT, YES_NO, YES_NO_TEXT, YES_NO_PHOTO, FILE_PHOTO, SELECT } =
  QUESTION_TYPE_IDS;
const { YES, NO, NA } = Answers;
const { ACCIDENT } = FormTypes;

function AccidentForm() {
  const {
    accidentAnswers,
    createAnswer,
    removeAnswer,
    currentQuestion,
    currentQuestionIndex,
    questionType,
    yesNoAnswer,
    setYesNoAnswer,
    answerInputValue,
    setAnswerInputValue,
    answerTextAreaValue,
    setAnswerTextAreaValue,
    answerSelectValue,
    setAnswerSelectValue,
    reactionTextAreaValue,
    setReactionTextAreaValue,
    reactionInCurrentAnswer,
    setReactionInCurrentAnswer,
    goToNextQuestion,
    goToPreviousQuestion,
    cameraVisible,
    setCameraVisible,
    clearInputFields,
    handleCloseForm,
    answerToCurrentQuestion,
    removePhoto,
    removeReactionComment,
  } = useAccidentForm();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [reactionModalOpen, setReactionModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [minPhotosNotReached, setMinPhotosNotReached] = useState(false);
  const [descriptionMissing, setDescriptionMissing] = useState(false);
  const [selectEmpty, setSelectEmpty] = useState(false);
  const [numberInputEmpty, setNumberInputEmpty] = useState(false);

  const reactionInAnswerVisible = !!reactionInCurrentAnswer.value;

  const { t } = useTranslation(["questionnaires", "side-navbar"], {
    useSuspense: true,
  });

  const contentContainerRef = useRef<HTMLDivElement>(null);
  const { isContentOverlow, isBottom, onScrollButtonClick, onScroll } =
    useContentOverflow({
      contentContainerRef,
      currentQuestionIndex,
    });

  const { setUpPhotosObjectStore, getNumberOfPhotos } = usePhotosObjectStore(ACCIDENT);

  const setTimeOut = useTimeout();

  const { minPhotos, maxPhotos } = getMinMaxPhotos(currentQuestion);

  const animate = !accidentAnswers.length;

  const isRequired = !!currentQuestion?.Required;

  const clearState = () => {
    if (cameraVisible) {
      hideCamera();
    }

    if (currentQuestionIndex > 0) {
      clearInputFields();
    }
  };

  // --------------- Modal handlers ---------------

  const openConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const openReactionModal = () => {
    setReactionModalOpen(true);
  };

  const closeReactionModal = () => {
    setReactionModalOpen(false);
  };

  // --------------- Textarea and camera handlers ---------------

  const displayCamera = useCallback(() => {
    setCameraVisible(true);
  }, [setCameraVisible]);

  const hideCamera = useCallback(() => {
    setCameraVisible(false);
  }, [setCameraVisible]);

  const handleCloseCameraView = () => {
    setSearchParams({});
  };

  // --------------- Navigate handlers ---------------

  const handleMoveForward = () => {
    clearInputFields();

    if (cameraVisible) {
      hideCamera();
    }

    goToNextQuestion();
  };

  // --------------- On click handlers ---------------

  const onCloseIconClick = () => {
    if (accidentAnswers.length) {
      openConfirmationModal();
    } else {
      handleCloseForm();
    }
  };

  // << BACK
  const onBackClick = () => {
    clearState();
    goToPreviousQuestion();
  };

  // FORWARD >>
  const onForwardClick = () => {
    handleMoveForward();
  };

  // BUTTON_YES
  const onYesClick = () => {
    if (!answerToCurrentQuestion) {
      createAnswer(YES);
    }

    if (answerToCurrentQuestion) {
      const { Value: Answer } = answerToCurrentQuestion;

      if (Answer === YES) {
        goToNextQuestion();
      }

      if (Answer === NO) {
        createAnswer(YES);
        clearInputFields();
      }
    }
  };

  // BUTTON_NA
  const onNaClick = () => {
    createAnswer(NA);

    if (answerToCurrentQuestion) {
      goToNextQuestion();
    }
  };

  // BUTTON_NO
  const onNoClick = async () => {
    switch (questionType) {
      case YES_NO:
        if (!answerToCurrentQuestion || answerToCurrentQuestion.Value === YES) {
          createAnswer(NO);
        } else {
          goToNextQuestion();
        }
        break;

      case YES_NO_TEXT:
        if (!answerToCurrentQuestion) {
          createAnswer(NO);
        }

        if (answerToCurrentQuestion) {
          const { ReactionValue } = answerToCurrentQuestion;

          if (!isRequired) {
            goToNextQuestion();
          } else {
            if (ReactionValue) {
              goToNextQuestion();
            } else {
              setDescriptionMissing(true);
            }
          }
        }
        break;

      case YES_NO_PHOTO:
        if (!answerToCurrentQuestion) {
          createAnswer(undefined);
        }

        if (answerToCurrentQuestion) {
          // --- If NOT REQUIRED ---
          if (!isRequired) {
            goToNextQuestion();
            return;
          }

          // --- If REQUIRED ---
          const questionId = answerToCurrentQuestion?.Question;
          const photosNumber = (await getNumberOfPhotos(questionId)) as number;

          if (minPhotosNotReached) return;

          if (photosNumber < minPhotos) {
            setMinPhotosNotReached(true);
          } else {
            goToNextQuestion();
          }
        }
    }
  };

  const onNextClick = async () => {
    hideCamera();

    switch (questionType) {
      case FILE_PHOTO:
        // --- NOT REQUIRED ---
        if (!isRequired) {
          if (!!answerToCurrentQuestion) {
            const { Question: id, ReactionValue } = answerToCurrentQuestion;
            const hasPhotos = !!((await getNumberOfPhotos(id)) as number);

            if (!hasPhotos && !ReactionValue) {
              removeAnswer(id);
            }
          }

          goToNextQuestion();

          return;
        }

        // --- REQUIRED ---
        const questionId = answerToCurrentQuestion?.Question
          ? answerToCurrentQuestion?.Question
          : "";
        const photosNumber = (await getNumberOfPhotos(questionId)) as number;

        if (minPhotosNotReached) return;

        if (photosNumber < minPhotos) {
          setMinPhotosNotReached(true);
        } else {
          if (!!answerToCurrentQuestion) {
            goToNextQuestion();
          } else {
            createAnswer();
            goToNextQuestion();
          }
        }
        break;

      case SELECT:
        // --- NOT REQUIRED ---
        if (!isRequired) {
          if (answerToCurrentQuestion) {
            if (!answerSelectValue.value) {
              const { Question: id } = answerToCurrentQuestion;

              removeAnswer(id);
            }
          }

          goToNextQuestion();
          return;
        }

        // --- REQUIRED ---
        if (!answerToCurrentQuestion?.Value) {
          setSelectEmpty(true);
        } else {
          createAnswer(answerSelectValue.value);
          goToNextQuestion();
        }
        break;

      case NUMERIC:
        // --- NOT REQUIRED ---
        if (!isRequired) {
          if (!!answerInputValue) {
            createAnswer();
          }

          if (answerToCurrentQuestion) {
            if (!answerInputValue) {
              const { Question: id } = answerToCurrentQuestion;
              removeAnswer(id);
            }
          }

          goToNextQuestion();
          return;
        }

        // --- REQUIRED ---
        if (!!answerToCurrentQuestion) {
          if (!!answerInputValue) {
            createAnswer();
            goToNextQuestion();
          } else {
            setNumberInputEmpty(true);
          }
        }

        if (!answerToCurrentQuestion) {
          if (!!answerInputValue) {
            createAnswer();
            goToNextQuestion();
          } else {
            setNumberInputEmpty(true);
          }
        }
        break;

      case TEXT:
        if (!!answerToCurrentQuestion) {
          if (!answerTextAreaValue) {
            const { Question: id } = answerToCurrentQuestion;
            removeAnswer(id);
          }
        }

        goToNextQuestion();
    }
  };

  const onEditIconClick = () => {
    setReactionTextAreaValue(reactionInCurrentAnswer.value);
    openReactionModal();
  };

  const onAddTextReactionBtnClick = () => {
    openReactionModal();
  };

  const onSaveReactionBtnClick = () => {
    createAnswer(undefined);
    setReactionTextAreaValue("");
    closeReactionModal();
  };

  const onTakePhotoBtnClick = () => {
    if (isIOS) {
      sessionStorage.setItem("returnPath", pathname);
      sessionStorage.setItem("goToPath", `${pathname}?cameraView=true`);
      navigate("/camera-detection");
    } else {
      setSearchParams({ cameraView: "true" });
    }
  };

  // --------------- Event handlers ---------------

  const onAnswerInputChange = (value: string) => {
    setAnswerInputValue(value);
  };

  const onAnswerTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement> | string) => {
    if (typeof e === "string") {
      setAnswerTextAreaValue(e);
    } else {
      setAnswerTextAreaValue(e.target.value);
    }
  };

  const onReactionTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement> | string) => {
    if (typeof e === "string") {
      setReactionTextAreaValue(e);
    } else {
      setReactionTextAreaValue(e.target.value);
    }
  };

  const onTextTranscriptChange = (textTranscript: string) => {
    if ([YES_NO_TEXT, YES_NO_PHOTO, FILE_PHOTO].some((type) => type === questionType)) {
      setReactionTextAreaValue(textTranscript);
    }

    if (questionType === TEXT) {
      setAnswerTextAreaValue(textTranscript);
    }
  };

  const onAnswerSelectChange = (value: string) => {
    createAnswer(value);
    setAnswerSelectValue({ label: value, value });
  };

  // --------------- Setting up photos object store ---------------

  useEffect(() => {
    setUpPhotosObjectStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------- Reaction to flipping through questions ---------------

  useEffect(() => {
    if (!currentQuestion) return;

    const answerValue = answerToCurrentQuestion?.Value as string;

    const handleTextReaction = () => {
      if (!answerToCurrentQuestion) return;

      const { Question: id, ReactionValue: value } = answerToCurrentQuestion;

      if (!!value) {
        setReactionInCurrentAnswer({
          id,
          value,
        });
      }
    };

    switch (questionType) {
      case TEXT:
        setAnswerTextAreaValue(answerValue);
        break;
      case NUMERIC:
        setAnswerInputValue(answerValue);
        break;
      case YES_NO:
        setYesNoAnswer(answerValue);
        break;
      case YES_NO_TEXT:
      case YES_NO_PHOTO:
        setYesNoAnswer(answerValue);
        if (answerToCurrentQuestion?.Value === NO) {
          handleTextReaction();
        }
        break;
      case FILE_PHOTO:
        handleTextReaction();
        break;
      case SELECT:
        setAnswerSelectValue({ label: answerValue, value: answerValue });
    }
  }, [
    currentQuestion,
    questionType,
    setAnswerInputValue,
    setAnswerTextAreaValue,
    setReactionInCurrentAnswer,
    setYesNoAnswer,
    answerToCurrentQuestion,
    cameraVisible,
    setAnswerSelectValue,
  ]);

  // --------------- Camera view handler ---------------

  useEffect(() => {
    const isCameraView = Boolean(searchParams.get("cameraView"));

    if (isCameraView) {
      displayCamera();
    } else {
      hideCamera();
    }
  }, [searchParams, displayCamera, hideCamera]);

  // --------------- Min photos number not reached handler ---------------

  useEffect(() => {
    if (minPhotosNotReached) {
      setTimeOut(() => {
        setMinPhotosNotReached(false);
      }, 2000);
    }
  }, [minPhotosNotReached, setTimeOut]);

  // --------------- Description missing reached handler ---------------

  useEffect(() => {
    if (descriptionMissing) {
      setTimeOut(() => {
        setDescriptionMissing(false);
      }, 2000);
    }
  }, [descriptionMissing, setTimeOut]);

  // --------------- Empty select handler ---------------

  useEffect(() => {
    if (selectEmpty) {
      setTimeOut(() => {
        setSelectEmpty(false);
      }, 2000);
    }
  }, [selectEmpty, setTimeOut]);

  // --------------- Empty number input handler ---------------

  useEffect(() => {
    if (numberInputEmpty) {
      setTimeOut(() => {
        setNumberInputEmpty(false);
      }, 2000);
    }
  }, [numberInputEmpty, setTimeOut]);

  return (
    <ContentContainer alarm ref={contentContainerRef} onScroll={onScroll}>
      <Content animate={animate} isContentOverlow={isContentOverlow}>
        <WarningBar animate={animate} />
        <Wrapper isContentOverlow={isContentOverlow}>
          <CloseIconContainer>
            <CloseIcon onClick={onCloseIconClick} />
          </CloseIconContainer>

          <NavHeader onBackClick={onBackClick} onForwardClick={onForwardClick} />
          <QuestionTitle required={isRequired} />

          {!!questionType && (
            <MainField
              hidden={
                questionType === TEXT ||
                questionType === NUMERIC ||
                questionType === SELECT
              }
            >
              <PhotosInAnswer
                formType={ACCIDENT}
                minPhotos={minPhotos}
                maxPhotos={maxPhotos}
                removePhoto={removePhoto}
                questionType={questionType}
                onTakePhotoBtnClick={onTakePhotoBtnClick}
                currentQuestionId={currentQuestion?.id as string}
                answerToCurrentQuestion={answerToCurrentQuestion}
                minPhotosNotReached={minPhotosNotReached}
                isRequired={isRequired}
              />

              <div>
                <PhotoPrompt
                  questionType={questionType}
                  onClick={onTakePhotoBtnClick}
                  currentQuestionId={currentQuestion?.id as string}
                  answerToCurrentQuestion={answerToCurrentQuestion}
                  minPhotos={minPhotos}
                  maxPhotos={maxPhotos}
                  minPhotosNotReached={minPhotosNotReached}
                  isRequired={isRequired}
                />
                <TextPrompt
                  questionType={questionType}
                  onClick={onAddTextReactionBtnClick}
                  currentQuestionId={currentQuestion?.id as string}
                  answerToCurrentQuestion={answerToCurrentQuestion}
                  descriptionMissing={descriptionMissing}
                />
              </div>

              {reactionInAnswerVisible && (
                <ReactionInAnswer
                  value={reactionInCurrentAnswer.value}
                  onEditIconClick={onEditIconClick}
                  removeReactionComment={removeReactionComment}
                />
              )}

              {cameraVisible && (
                <WebcamCapture
                  createAnswer={createAnswer}
                  currentQuestionId={currentQuestion?.id}
                  maxPhotos={maxPhotos}
                  handleCloseCameraView={handleCloseCameraView}
                  formType={ACCIDENT}
                />
              )}
            </MainField>
          )}

          {currentQuestion && !questionType && <NoTypeWarning />}

          {!!questionType && (
            <QuestionActions
              formType={ACCIDENT}
              currentQuestion={currentQuestion as QuestionObject}
              onYesClick={onYesClick}
              onNoClick={onNoClick}
              onNaClick={onNaClick}
              onNextClick={onNextClick}
              onTextTranscriptChange={onTextTranscriptChange}
              onAnswerInputChange={onAnswerInputChange}
              answerInputValue={answerInputValue}
              onAnswerTextAreaChange={onAnswerTextAreaChange}
              answerTextAreaValue={answerTextAreaValue}
              onAnswerSelectChange={onAnswerSelectChange}
              answerSelectValue={answerSelectValue}
              yesNoAnswer={yesNoAnswer}
              answerToCurrentQuestion={answerToCurrentQuestion}
              removeAnswer={removeAnswer}
              searchParams={searchParams}
            />
          )}

          {isContentOverlow && (
            <ScrollButton isBottom={isBottom} onClick={onScrollButtonClick} />
          )}
        </Wrapper>
      </Content>

      {/* --------------- Modals --------------- */}
      <ReactionModal
        open={reactionModalOpen}
        onClose={closeReactionModal}
        questionType={questionType}
        required={isRequired}
        minTextLength={currentQuestion?.RequiredMinText}
        maxTextLength={currentQuestion?.MaxText}
        reactionTextAreaValue={reactionTextAreaValue}
        onReactionTextAreaChange={onReactionTextAreaChange}
        onTextTranscriptChange={onTextTranscriptChange}
        onSaveReactionBtnClick={onSaveReactionBtnClick}
      />

      <ConfirmationModal
        message={t("modal.message", { ns: "side-navbar" })}
        onClick={handleCloseForm}
        onClose={closeConfirmationModal}
        open={confirmationModalOpen}
        buttonLabel={t("modal.button-label", { ns: "side-navbar" })}
      />
    </ContentContainer>
  );
}

export default AccidentForm;
