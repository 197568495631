import { CountryCodes } from "types/types";

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let header = {
    title: "Tasks",
    photos: "Task > photos",
    documents: "Task > documents",
  };

  let tabs = {
    other: "Other",
    assigned: "Mine",
  };

  let labels = {
    dateCreated: "Created",
    deadline: "Deadline",
    related: "Related",
    attachments: "Attachments",
    btn: {
      add: "Add",
      takePoto: "Take",
      takeAnother: "Take another",
      select: "Select",
      selectExisting: "Select existing",
      delete: "Delete",
      done: "Done",
    },
  };

  let alert = {
    success: {
      photoAttached: "Photo has been attached to task!",
      documentAttached: "Document has been attached to task!",
      photoDeleted: "Photo deleted!",
      documentDeleted: "Photo deleted!",
    },
    error: {
      unableToLoad: "Unable to load tasks",
      failedToAttachPhoto: "Failed to attach photo...",
      failedToAttachDocument: "Failed to attach document...",
      failedToDletePhoto: "Failed to delete photo",
      failedToDleteDocument: "Failed to delete document",
    },
    warning: {
      notPhoto: "Selected file is not an image.",
      notDocument: "Selected file is not a document.",
      noPermissionToDeletePhoto: "You do not have permission to delete this photo",
    },
    info: {
      noAssigned: "No assigned tasks...",
      noTasks: "No tasks...",
    },
  };

  let placeholder = {
    noDocuments: "No documents",
  };

  let status = {
    in_progress: "In progress",
  };

  let modal = {
    message: {
      photo: "Are you sure you want to delete the photo?",
      document: "Are you sure you want to delete the document?",
    },
  };

  let tooltip = {
    photo: "Attach photo",
    document: "Attach document",
  };

  if (isPolish) {
    header = {
      title: "Zadania",
      photos: "Zadanie > zdjęcia",
      documents: "Zadanie > dokumenty",
    };

    tabs = {
      other: "Pozostałe",
      assigned: "Moje",
    };

    alert = {
      success: {
        photoAttached: "Zdjęcie dodane do zadania!",
        documentAttached: "Dokument dodany do zadania!",
        photoDeleted: "Zdjęcie zostało usunięte!",
        documentDeleted: "Dokument został usunięty!",
      },
      error: {
        unableToLoad: "Nie można pobrać zadań",
        failedToAttachPhoto: "Nie udało się dodać zdjęcia...",
        failedToAttachDocument: "Nie udało się dodać dokumentu...",
        failedToDletePhoto: "Nie udało się usunąć zdjęcia...",
        failedToDleteDocument: "Nie udało się usunąć dokumentu...",
      },
      warning: {
        notPhoto: "Wybrany plik nie jest zdjęciem",
        notDocument: "Wybrany plik nie jest dokumentem.",
        noPermissionToDeletePhoto: "Nie masz uprawnień do usunięcia tego zdjęcia",
      },
      info: {
        noAssigned: "Brak przypisanych zadań...",
        noTasks: "Brak zadań...",
      },
    };

    labels = {
      dateCreated: "Utworzone",
      deadline: "Deadline",
      related: "Powiązane z",
      attachments: "Załączniki",
      btn: {
        add: "Dodaj",
        takePoto: "Zrób",
        takeAnother: "Zrób kolejne",
        select: "Wybierz",
        selectExisting: "Wybierz istniejące",
        delete: "Usuń",
        done: "Gotowe",
      },
    };

    placeholder = {
      noDocuments: "Brak dokumentów",
    };

    status = {
      in_progress: "W trakcie",
    };

    modal = {
      message: {
        photo: "Czy na pewno chcesz usunąć zdjęcie?",
        document: "Czy na pewno chcesz usunąć dokument?",
      },
    };

    tooltip = {
      photo: "Załącz zdjęcie",
      document: "Załącz dokument",
    };
  }

  return { header, tabs, alert, labels, status, placeholder, modal, tooltip };
};
