import { CountryCodes } from "types/types";

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let label = {
    answersList: "Answers list",
    checkAll: "All",
    unCheckAll: "Uncheck",
  };

  if (isPolish) {
    label = {
      answersList: "Lista odpowiedzi",
      checkAll: "Wszystkie",
      unCheckAll: "Odznacz",
    };
  }

  return { label };
};
