import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import {
  CountryCodes,
  EntitiesEnum,
  Entity,
  MachineProcessStatus,
  MachineProcessStatuses,
} from "types/types";
import { getMachineProcessStatusTranslation } from "utils/utils";

interface Info {
  entity: Entity;
  entityId: string;
  name: string;
  status: MachineProcessStatus;
  statusTranslated: string;
  addressLabel: string;
  Misc: string | null;
}

const infoInitial: Info = {
  entity: undefined,
  entityId: "",
  name: "",
  status: MachineProcessStatuses.PUBLISHED,
  statusTranslated: "",
  addressLabel: "",
  Misc: "",
};

const { MACHINE, PROCESS } = EntitiesEnum;

function useMachineProcessInfo() {
  const [info, setinfo] = useState(infoInitial);
  const { state } = useContext(QuestionnairesContext);

  const {
    i18n: { language },
  } = useTranslation();

  const isPolish = language.includes(CountryCodes.PL);

  useEffect(() => {
    if (!state.machineProcess) return;

    const {
      machineProcess: { Machine, Process },
    } = state;

    const getAddressTranslaion = () => (isPolish ? "Adres" : "Address");

    let entityObject: Info = {
      entity: undefined,
      entityId: "",
      name: "",
      status: MachineProcessStatuses.PUBLISHED,
      statusTranslated: "",
      addressLabel: "",
      Misc: "",
    };

    if (Machine) {
      entityObject = {
        entity: MACHINE,
        entityId: Machine.id,
        name: Machine.MachineModel,
        status: Machine.status,
        statusTranslated: getMachineProcessStatusTranslation(language, Machine.status),
        addressLabel: getAddressTranslaion(),
        Misc: Machine.Misc,
      };
    }

    if (Process) {
      entityObject = {
        entity: PROCESS,
        entityId: Process.id,
        name: Process.ProcessName,
        status: Process.status,
        statusTranslated: getMachineProcessStatusTranslation(language, Process.status),
        addressLabel: getAddressTranslaion(),
        Misc: Process.Misc,
      };
    }

    setinfo(entityObject);
  }, [state, isPolish, language]);

  return info;
}

export default useMachineProcessInfo;
