import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";

import { device, isMobile } from "utils/utils";
import useSelectedAnswers from "hooks/useSelectedAnswers";
import useAssessmentResponse from "hooks/useAssessmentResponse";
import { Answers, CreatedAnswer, QUESTION_TYPE_IDS, YesNoAnswer } from "types/types";
import { getCommonTranslations } from "translations/common.translations";
import { getTranslations } from "./translations/answers-list.translations";

import { ReactComponent as ChevronIcon } from "assets/icons/chevron-down.svg";

import CheckboxIcon from "components/atoms/checkbox-icon/CheckboxIcon";
import ThumbIcon from "components/molecules/ThumbIcon";
import PhotoPreview from "./components/photo-preview/PhotoPreview";

const Container = styled.div`
  width: 90%;
  margin: 1rem auto 0 auto;
  overflow: auto;
  flex-basis: 75%;

  @media ${device.iPad} {
    flex-basis: 87%;
  }
`;

const List = styled.ul`
  border-radius: 0.6rem;
  overflow: auto;
`;

const ListItem = styled.li`
  padding: 0.5rem 0;
  padding-left: 0.5rem;
  display: flex;
  background-color: ${({ theme }) => theme.white};

  &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }
`;

const LeftContainer = styled.div`
  flex-basis: 86%;
  padding: 0 0.5rem;

  @media ${device.iPad} {
    flex-basis: 92%;
  }
`;

const RightContainer = styled.div`
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  flex-basis: 14%;

  @media ${device.iPad} {
    flex-basis: 8%;
  }
`;

const QuestionContainer = styled.div`
  height: 4rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const QuestionNumber = styled.span`
  &::after {
    content: ".";
  }
`;

const QuestionTitle = styled.span`
  font-family: GothamBold;
  ${({ theme }) => theme.ellipsisMultiline}
`;

const AnswerContainer = styled.div`
  height: 2.8rem;
  margin-top: 0.5rem;
  background-color: rgba(243, 245, 248, 0.9);
  border-radius: 0.4rem;
  position: relative;
  padding: 0.5rem 0;
`;

const AnswerValue = styled.div<{ alignRight?: boolean; italic: boolean }>`
  ${({ theme }) => theme.ellipsisMultiline};
  -webkit-line-clamp: 1;
  max-width: 100%;
  height: 100%;
  font-size: 1.4rem;
  padding-left: 0.5rem;

  .icon-yes,
  .icon-no {
    width: 1.8rem;
    height: 1.8rem;

    position: absolute;
    right: 1rem;
  }

  .icon-yes {
    top: 0.6rem;
    path {
      fill: ${({ theme }) => theme.yesButton};
    }
  }

  .icon-no {
    top: 0.4rem;

    path {
      fill: ${({ theme }) => theme.noButton};
      transform: translateY(-0.3rem);
    }
  }

  ${({ alignRight }) =>
    alignRight &&
    css`
      text-align: right;
      padding-right: 1rem;
    `}

  ${({ italic }) =>
    italic &&
    css`
      font-style: italic;
      opacity: 0.7;
    `}
`;

const TopPanel = styled.div`
  width: 90%;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
`;

const Switch = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.25rem;
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
  flex-basis: 60%;
`;

const IconContainer = styled.div<{ listVisible: boolean }>`
  .chevron-icon {
    width: 1.8rem;
    height: 1.8rem;
    transform: translateY(0.2rem);

    ${({ listVisible }) =>
      listVisible &&
      css`
        transform: rotate(180deg) translateY(-0.2rem);
      `}
  }
`;

const SelectAllToggle = styled.div<{ isVsible: boolean; isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  visibility: hidden;
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};

  ${({ isVsible }) =>
    isVsible &&
    css`
      visibility: visible;
    `}
`;

const Label = styled.div`
  user-select: none;
`;

const { NO, NA } = Answers;
const { YES_NO_PHOTO, YES_NO, YES_NO_TEXT, TEXT, FILE_PHOTO, NUMERIC, SELECT } =
  QUESTION_TYPE_IDS;

function AnswersList() {
  const [listVisible, setListVisible] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const { assessmentData } = useAssessmentResponse();
  const { selectedAnswers, setSelectedAnswers } = useSelectedAnswers();
  const {
    i18n: { language },
  } = useTranslation();
  const {
    labels: { notApplicable },
    info: { noPhotos },
  } = getCommonTranslations(language);
  const { label } = getTranslations(language);

  const isFirstRender = useRef(true);

  let content = null;

  const toggleList = () => {
    setListVisible((prev) => !prev);
  };

  const toggleAllSelected = () => {
    if (!assessmentData) return;
    const { createdAnswers } = assessmentData;

    let answersList: CreatedAnswer[] = !allSelected ? createdAnswers : [];

    setSelectedAnswers(answersList);
  };

  // *************** On click handlers ***************

  const onSwitchClick = () => {
    toggleList();
  };

  const onItemClick = (answer: CreatedAnswer) => () => {
    if (!selectedAnswers) return;

    const isAlreadySelected = selectedAnswers.some(({ id }) => id === answer.id);

    if (isAlreadySelected) {
      const updatedAnswers = selectedAnswers.filter(({ id }) => id !== answer.id);

      setSelectedAnswers(updatedAnswers);
    } else {
      const updatedAnswers = [...selectedAnswers, answer];
      setSelectedAnswers(updatedAnswers);
    }
  };

  const onSelectAllClick = () => {
    toggleAllSelected();
  };

  // *************** Content ***************

  if (assessmentData) {
    const { createdAnswers } = assessmentData;

    content = (
      <List>
        {createdAnswers.map((answer, index) => {
          const { QuestionType, Value, FilesGallery } = answer;
          const checked = !!selectedAnswers?.some(({ id }) => answer.id === id);
          const alignRight = QuestionType !== TEXT;
          let italic = false;
          let value = Value as YesNoAnswer;

          let answerValue = null;

          switch (QuestionType) {
            case TEXT:
            case NUMERIC:
            case SELECT:
              answerValue = Value;
              break;
            case YES_NO:
            case YES_NO_TEXT:
              if (value === NA) {
                answerValue = notApplicable;
                italic = true;
              } else {
                answerValue = <ThumbIcon value={value} />;
              }
              break;

            case YES_NO_PHOTO:
              if (value === NA) {
                answerValue = notApplicable;
                italic = true;
              } else {
                answerValue = (
                  <>
                    {!!FilesGallery && FilesGallery.length && (
                      <PhotoPreview FilesGallery={FilesGallery} moveLeft />
                    )}
                    <ThumbIcon value={value} />
                  </>
                );
              }

              break;

            case FILE_PHOTO:
              italic = !FilesGallery || !FilesGallery.length;

              answerValue =
                !!FilesGallery && FilesGallery.length ? (
                  <PhotoPreview FilesGallery={FilesGallery} />
                ) : (
                  noPhotos
                );
          }

          return (
            <ListItem key={answer.id} onClick={onItemClick(answer)}>
              <LeftContainer>
                <QuestionContainer>
                  <QuestionNumber>{index + 1}</QuestionNumber>
                  <QuestionTitle>{answer.OriginalText}</QuestionTitle>
                </QuestionContainer>

                <AnswerContainer>
                  <AnswerValue alignRight={alignRight} italic={italic}>
                    {answerValue}
                  </AnswerValue>
                </AnswerContainer>
              </LeftContainer>

              <RightContainer>
                <CheckboxIcon checked={checked} dark />
              </RightContainer>
            </ListItem>
          );
        })}
      </List>
    );
  }

  // *************** Effect handlers ***************

  useEffect(() => {
    if (!isFirstRender.current || !assessmentData) return;

    const { createdAnswers } = assessmentData;
    const negativeAnswers = createdAnswers.filter(({ Value }) => Value === NO);

    setSelectedAnswers(negativeAnswers);
    isFirstRender.current = false;
  }, [assessmentData, selectedAnswers, setSelectedAnswers]);

  useEffect(() => {
    if (!assessmentData) return;

    const { createdAnswers } = assessmentData;

    setAllSelected(createdAnswers.length === selectedAnswers.length);
  }, [selectedAnswers, assessmentData]);

  return (
    <>
      <TopPanel>
        <SelectAllToggle
          isVsible={listVisible}
          onClick={onSelectAllClick}
          isMobile={isMobile}
        >
          <CheckboxIcon checked={allSelected} dark small />
          <Label>{allSelected ? label.unCheckAll : label.checkAll}</Label>
        </SelectAllToggle>

        <Switch onClick={onSwitchClick} isMobile={isMobile}>
          <Label>{label.answersList}</Label>
          <IconContainer listVisible={listVisible}>
            <ChevronIcon className='chevron-icon' />
          </IconContainer>
        </Switch>
      </TopPanel>
      <Container>{listVisible ? content : null}</Container>
    </>
  );
}

export default AnswersList;
