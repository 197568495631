import { CountryCodes } from "types/types";

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let header = {
    title: "Task",
    photos: "photos",
  };

  let labels = {
    dateCreated: "Created",
    userCreated: "Created by",
    btn: {
      save: "Save",
      quit: "Quit",
    },
  };

  let placeholders = {
    comment: {
      create: "Add comment",
      edit: "No comment",
    },
  };

  let notificationMessage = {
    success: "Changes saved!",
    error: "Unable to save changes",
  };

  let titles = {
    editTitle: "Edit description",
  };

  let modal = {
    header: {
      Title: "Edit task description",
      Comment: "Edit task comment",
    },
  };

  if (isPolish) {
    header = {
      title: "Zadanie",
      photos: "zdjęcia",
    };

    labels = {
      dateCreated: "Utworzone",
      userCreated: "Utworzone przez",
      btn: {
        save: "Zapisz",
        quit: "Zakończ",
      },
    };

    placeholders = {
      comment: {
        create: "Dodaj komentarz",
        edit: "Brak komentarza",
      },
    };

    notificationMessage = {
      success: "Zapisano zmiany!",
      error: "Nie udało się zapisać zmian",
    };

    titles = {
      editTitle: "Edytuj opis",
    };

    modal = {
      header: {
        Title: "Edytuj opis zadania",
        Comment: "Edytuj komentarz zadania",
      },
    };
  }

  return { header, labels, notificationMessage, titles, modal, placeholders };
};
