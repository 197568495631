import { Answer, CreatedAnswer } from "types/types";

export const createAnswersList = (
  answers: Answer[] | CreatedAnswer[],
  questionLabel: string,
) =>
  answers
    .map((answer) => {
      return `[${questionLabel}: ${answer.sort}] ${answer.OriginalText}${
        answer.Value ? `\n\n> ${answer.Value}` : ""
      }${answer.ReactionValue ? `\n\n> ${answer.ReactionValue}` : ""}`;
    })
    .join("\n\n");

export const getTaskDescription = (title: string, answersList: string, label: string) =>
  title + `${!!answersList ? `\n\n${label}:\n\n` : ""}` + answersList;

export const createFileObjectForPayload = (id: string) => ({
  directus_files_id: { id },
  Tasks_id: "+",
});

export const getCurrentSlide = (searchParams: URLSearchParams) => {
  const slide = searchParams.get("slide");

  return slide ? Number(slide) : 0;
};
