import styled, { css } from "styled-components/macro";

import { device } from "utils/utils";

import { FlexContainer } from "./generalStyles";

export const Container = styled.div`
  ${({ theme }) => theme.fillUpRemainingSpace};
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Wrapper = styled.div<{ isMobile: boolean }>`
  position: relative;
  ${({ theme }) => theme.fillUpRemainingSpace};

  .icon {
    position: absolute;
    bottom: 0.5rem;
    right: -0.5rem;
    cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "none")};
    transition: opacity 0.25s ease-out;

    &:hover {
      opacity: 0.9;
    }
  }
`;

export const Header = styled.div<{
  isMobile: boolean;
  noUnderline?: boolean;
  noPadding?: boolean;
}>`
  position: relative;
  padding-top: 2rem;

  ${({ noUnderline }) =>
    !noUnderline &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.primary};
    `}

  ${({ noPadding }) =>
    !noPadding &&
    css`
      padding-bottom: 1rem;
    `}

  .close-icon {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: -0.3rem;
    right: -0.9rem;
    cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "none")};
  }
`;

export const Title = styled.div`
  font-size: 1.6rem;
  margin-top: 1rem;

  @media screen and (min-width: 340px) {
    font-size: 1.8rem;
  }

  @media screen and (min-width: 360px) {
    font-size: 2rem;
  }

  font-family: AuraAspect;
  text-align: center;
`;

export const InfoContainer = styled.div`
  padding: 1rem 0.5rem;
  position: relative;

  @media ${device.iPad} {
    font-size: 1.8rem;
  }
`;

export const TaskTitleContainer = styled.div<{
  isMobile: boolean;
  blocked?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: flex-start;
  min-height: 3.1rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: ${({ theme }) => theme.white};
  border-radius: 0.4rem;
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};

  ${({ blocked }) =>
    blocked &&
    css`
      cursor: not-allowed;
    `}
`;

export const TaskTitle = styled.textarea`
  flex-basis: 100%;
  font-size: 1.8rem;
  resize: none;
  border: none;
  outline: none;
  cursor: inherit;

  ${({ theme }) => theme.ellipsisMultiline};
  -webkit-line-clamp: 2;
`;

export const Value = styled.span`
  font-family: GothamBold;
`;

export const Status = styled(FlexContainer)``;
export const StatusLabel = styled.span``;

export const DateCreated = styled(FlexContainer)`
  padding: 0.5rem;
  font-size: 1.4rem;
  margin-top: 1rem;
`;
export const DateCreatedLabel = styled.span``;
export const DateCreatedValue = styled(Value)``;

export const Divider = styled.div`
  height: 1rem;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  padding-bottom: 0.5rem;
`;
