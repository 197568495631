import styled from "styled-components/macro";

import { ReactComponent as Close } from "assets/icons/close.svg";

const Container = styled.div<{ disabled: boolean }>`
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 0;
  right: 0;

  z-index: ${({ theme }) => theme.level1};

  @media screen and (min-height: 616px) {
    width: 3rem;
    height: 3rem;
  }

  .close-icon {
    width: 100%;
    height: 100%;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
`;

type CloseIconProps = {
  onClick: () => void;
  disabled?: boolean;
  customClass?: string;
  visible?: boolean;
};

function CloseIcon({
  onClick,
  disabled = false,
  customClass = "",
  visible = true,
}: CloseIconProps) {
  return visible ? (
    <Container disabled={disabled} className={customClass}>
      <Close className='close-icon' onClick={onClick} />
    </Container>
  ) : null;
}

export default CloseIcon;
