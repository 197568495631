import { CountryCodes } from "types/types";

export const getLoginTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let labels = {
    password: "Password",
    forgotPassword: "Forgot password?",
    signInWithQr: "Sign in with QR code",
  };

  let placeholders = {
    email: "Enter email",
    password: "Enter password",
  };

  let button = {
    label: "Sign in",
  };

  let errors = {
    invalidLoginDetails: "Invalid login details!",
    unableToSignIn: "Unable to sign in",
    invalidEmail: "Invalid email",
  };

  if (isPolish) {
    labels = {
      password: "Hasło",
      forgotPassword: "Nie pamiętasz hasła?",
      signInWithQr: "Zaloguj się kodem QR",
    };

    placeholders = {
      email: "Podaj email",
      password: "Podaj hasło",
    };

    button = {
      label: "Zaloguj się",
    };

    errors = {
      invalidLoginDetails: "Nieprawidłowe dane logowania!",
      unableToSignIn: "Nie można się zalogować.",
      invalidEmail: "Niepoprawny email",
    };
  }

  return { labels, placeholders, button, errors };
};
