import styled from "styled-components/macro";

import { DirectusFile } from "types/tasks.types";

import { isMobile } from "utils/utils";
import useDevices from "hooks/useDevices";
import useTaskAttachments from "hooks/useTaskAttachments";

import { ReactComponent as DocumentIcon } from "assets/icons/pdf.svg";
import { ReactComponent as PhotoIcon } from "assets/icons/add-photo.svg";

import { StyledLabel } from "styles/generalStyles";
import { FilesCount } from "pages/user-tasks/styles/tasks.styles";

const Container = styled.div`
  margin-top: 1rem;
`;

const PreviewContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const FilesPreviewContainer = styled.div<{ isMobile: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: ${({ theme }) => theme.white};
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
  position: relative;

  .icon-photo {
    width: 2.2rem;
    height: 2.2rem;
    opacity: 0.5;
  }

  .icon-document {
    width: 2.2rem;
    height: 2.2rem;
  }
`;

const Wrapper = styled.div`
  width: 4.2rem;
  height: 4.2rem;
  position: relative;
`;

const PhotosPreview = styled.img`
  width: 100%;
`;

type DirectusFileProps = {
  files: DirectusFile[];
  label: string;
  navigateToTaskPhotos: () => void;
  navigateToTaskDocuments: () => void;
};

function Attachments({
  files,
  label,
  navigateToTaskPhotos,
  navigateToTaskDocuments,
}: DirectusFileProps) {
  const { taskPhotosObjects, documentFiles } = useTaskAttachments(files);
  const { isVideoInput } = useDevices();
  let src = "";

  if (taskPhotosObjects.length) {
    const lastPhotoIndex = taskPhotosObjects.length - 1;
    src = taskPhotosObjects[lastPhotoIndex].src;
  }

  return (
    <Container>
      <StyledLabel>{label}</StyledLabel>
      <PreviewContainer>
        {isVideoInput && (
          <Wrapper>
            <FilesPreviewContainer isMobile={isMobile} onClick={navigateToTaskPhotos}>
              {!!taskPhotosObjects.length ? (
                <PhotosPreview src={src} alt='' />
              ) : (
                <PhotoIcon className='icon-photo' />
              )}
            </FilesPreviewContainer>
            {!!taskPhotosObjects.length && (
              <FilesCount>{taskPhotosObjects.length}</FilesCount>
            )}
          </Wrapper>
        )}

        {!!documentFiles.length && (
          <Wrapper>
            <FilesPreviewContainer isMobile={isMobile} onClick={navigateToTaskDocuments}>
              <DocumentIcon className='icon-document' />
            </FilesPreviewContainer>
            <FilesCount>{documentFiles.length}</FilesCount>
          </Wrapper>
        )}
      </PreviewContainer>
    </Container>
  );
}

export default Attachments;
