import { useEffect, useState } from "react";
import { CreatedAnswer } from "types/types";

const getLocalData = () => {
  const localData = sessionStorage.getItem("selectedAnswers");

  return !!localData ? JSON.parse(localData) : [];
};

function useSelectedAnswers() {
  const [selectedAnswers, setSelectedAnswers] = useState<CreatedAnswer[]>(getLocalData());
  const AnswersLinkWithTask = selectedAnswers.map(({ id }) => id);

  useEffect(() => {
    sessionStorage.setItem("selectedAnswers", JSON.stringify(selectedAnswers));
  }, [selectedAnswers]);

  return { selectedAnswers, setSelectedAnswers, AnswersLinkWithTask };
}

export default useSelectedAnswers;
