import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { DetectedBarcode } from "barcode-detector";

import {
  QUESTION_TYPE_IDS,
  Answers,
  Answer,
  FormType,
  ErrorPosition,
  QuestionObject,
} from "types/types";
import useTimeout from "hooks/useTimeout";
import useModal from "hooks/useModal";
import useAnimate from "hooks/useAnimate";
import usePhotosObjectStore from "hooks/usePhotosObjectStore";
import useDictaphoneAccess from "hooks/useDictaphoneAccess";
import {
  isMobile,
  checkIfAnswerContainsTextReaction,
  MAX_INT_VALUE,
  scrollToBottom,
  isIOS,
} from "utils/utils";
import { getTextValueAfterScan } from "utils/barcode-scanner.utils";
import { getTranslations } from "./translations/question-actions.translations";

import { ReactComponent as XIcon } from "assets/icons/close.svg";

import Input from "components/atoms/Input";
import Button from "components/atoms/Button";
import ButtonLabel from "./components/button-label/ButtonLabel";
import ConfirmationModal from "components/organisms/ConfirmationModal";
import AnswerSelect from "./components/answer-select/AnswerSelect";

import {
  Container,
  Actions,
  BtnContainer,
  InputWrapper,
  NextBtnContainer,
  ResetQuestion,
} from "./styles/question-actions.styles";
import InputText from "./components/input-text/InputText";
import NextBtnLabel from "./components/next-btn-label/NextBtnLabel";
import CloseIcon from "components/molecules/close-icon/CloseIcon";

type QuestionActionsProps = {
  formType: FormType;
  currentQuestion: QuestionObject;
  onYesClick: () => void;
  onNoClick: () => void;
  onNaClick: () => void;
  onNextClick: () => void;
  onTextTranscriptChange: (value: string) => void;
  onAnswerInputChange: (value: string) => void;
  answerInputValue: string;
  onAnswerTextAreaChange: (e: ChangeEvent<HTMLTextAreaElement> | string) => void;
  answerTextAreaValue: string | undefined;
  onAnswerSelectChange: (value: string) => void;
  answerSelectValue: { label: string; value: string };
  yesNoAnswer: string;
  answerToCurrentQuestion: Answer | undefined;
  removeAnswer: (Question: string) => Promise<void>;
  searchParams: URLSearchParams;
  selectEmpty?: boolean;
  numberInputEmpty?: boolean;
};

const { FILE_PHOTO, NUMERIC, TEXT, YES_NO_PHOTO, YES_NO_TEXT, SELECT } =
  QUESTION_TYPE_IDS;
const { YES, NO, NA } = Answers;
const { BOTTOM_LEFT } = ErrorPosition;

function QuestionActions({
  formType,
  currentQuestion,
  onYesClick,
  onNoClick,
  onNaClick,
  onNextClick,
  onTextTranscriptChange,
  onAnswerInputChange,
  answerInputValue = "",
  onAnswerTextAreaChange,
  answerTextAreaValue,
  onAnswerSelectChange,
  answerSelectValue,
  yesNoAnswer,
  answerToCurrentQuestion,
  removeAnswer,
  searchParams,
  selectEmpty = false,
  numberInputEmpty = false,
}: QuestionActionsProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [isBarcodeScannerVisible, setIsBarcodeScannerVisible] = useState(false);
  const [numberInputError, setNumberInputError] = useState({ message: "" });

  const { open, openModal, closeModal } = useModal();
  const {
    i18n: { language },
  } = useTranslation("common");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { error, modal, label } = getTranslations(language);

  const { getNumberOfPhotos } = usePhotosObjectStore(formType);
  const { isDictaphoneAccessible } = useDictaphoneAccess();
  const { animate, duration } = useAnimate();

  const setTimeOut = useTimeout();

  const { AllowNA, QuestionType, Options, RequiredMinText, Required, MaxText } =
    currentQuestion;

  const Question = answerToCurrentQuestion?.Question ?? "";

  let content;

  const openBarcodeScanner = () => {
    setIsBarcodeScannerVisible(true);
  };

  const closeBarcodeScanner = () => {
    setIsBarcodeScannerVisible(false);
  };

  // --------------- Error handler ---------------

  const setErrorMessage = (message: string) => {
    setNumberInputError({ message });
  };

  // --------------- On click handlers ---------------

  const handleYesClick = async () => {
    switch (QuestionType) {
      case YES_NO_PHOTO:
        const containsPhotos = !!(await getNumberOfPhotos(Question));

        if (containsPhotos) {
          openModal();
        } else {
          onYesClick();
        }
        break;

      case YES_NO_TEXT:
        if (checkIfAnswerContainsTextReaction(answerToCurrentQuestion)) {
          openModal();
        } else {
          onYesClick();
        }
        break;

      default:
        onYesClick();
    }
  };

  const onConfirmationClick = () => {
    removeAnswer(Question);
    closeModal();
  };

  const onResetQuestionClick = () => {
    openModal();
  };

  const onBarcodeIconClick = () => {
    openBarcodeScanner();
  };

  const onTextIconClick = () => {
    closeBarcodeScanner();
  };

  // --------------- Event handlers ---------------

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setTimeOut(() => {
      setIsFocused(false);
    }, 100);
  };

  const onNumberInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(e.target.value);

    if (isNaN(inputValue)) return;

    const answerValue =
      inputValue !== Infinity ? `${Math.abs(Math.trunc(inputValue))}` : `${inputValue};`;

    onAnswerInputChange(answerValue);

    if (inputValue > MAX_INT_VALUE) {
      setErrorMessage(error.number_too_large);
    } else {
      if (numberInputError.message) {
        setErrorMessage("");
      }
    }
  };

  const onScan = (result: DetectedBarcode[]) => {
    const { rawValue } = result[0];
    const value = getTextValueAfterScan(answerTextAreaValue, rawValue);

    onAnswerTextAreaChange(value);
    closeBarcodeScanner();
  };

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (!textareaRef.current) return;

    const { current: textArea } = textareaRef;

    scrollToBottom(textArea);
  }, [answerTextAreaValue]);

  useEffect(() => {
    const message = numberInputEmpty ? error.empty_field : "";

    setErrorMessage(message);
  }, [numberInputEmpty, error.empty_field]);

  // --------------- Content ---------------

  switch (QuestionType) {
    case FILE_PHOTO:
      content = (
        <Actions>
          <NextBtnContainer>
            <Button
              label={<NextBtnLabel label={label.btn.save_next} />}
              large
              question
              onClick={onNextClick}
              customClass='save-next-btn'
            />
          </NextBtnContainer>
        </Actions>
      );
      break;

    case NUMERIC:
      content = (
        <>
          <InputWrapper animate={animate} duration={`${duration}ms`}>
            <CloseIcon
              onClick={() => {
                onAnswerInputChange("");
              }}
              customClass='close-icon'
              visible={!!answerInputValue}
            />
            <Input
              large
              onChange={onNumberInputChange}
              value={answerInputValue}
              type='tel'
              onFocus={onFocus}
              onBlur={onBlur}
              error={numberInputError.message}
              name='number-input'
              errorPosition={isMobile ? BOTTOM_LEFT : undefined}
              alignRight
            />
          </InputWrapper>
          <Actions>
            <NextBtnContainer>
              <Button
                label={<NextBtnLabel label={label.btn.save_next} />}
                large
                question
                onClick={onNextClick}
                disabled={!!numberInputError.message}
                customClass='save-next-btn'
              />
            </NextBtnContainer>
          </Actions>
        </>
      );
      break;

    case TEXT:
      content = (
        <InputText
          onScan={onScan}
          textareaRef={textareaRef}
          minTextLength={RequiredMinText}
          maxTextLength={MaxText}
          textAreaValue={answerTextAreaValue}
          onTextAreaChange={onAnswerTextAreaChange}
          isBarcodeScannerVisible={isBarcodeScannerVisible}
          isDictaphoneAccessible={isDictaphoneAccessible}
          onTextTranscriptChange={onTextTranscriptChange}
          questionType={QuestionType}
          searchParams={searchParams}
          onBarcodeIconClick={onBarcodeIconClick}
          onTextIconClick={onTextIconClick}
          btnLabel={label.btn.save_next}
          onNextClick={onNextClick}
          language={language}
          required={Required}
        />
      );
      break;

    case SELECT:
      content = (
        <>
          <AnswerSelect
            options={Options}
            onAnswerSelectChange={onAnswerSelectChange}
            answerSelectValue={answerSelectValue}
            selectEmpty={selectEmpty}
          />

          <Actions>
            <NextBtnContainer>
              <Button
                label={<NextBtnLabel label={label.btn.save_next} />}
                large
                question
                onClick={onNextClick}
                customClass='save-next-btn'
                // disabled={!!numberInputError.message}
              />
            </NextBtnContainer>
          </Actions>
        </>
      );
      break;

    default:
      content = (
        <Actions spaceBetween isNA={!!AllowNA}>
          {/* NO BUTTON */}
          <BtnContainer
            isNA={!!AllowNA}
            className={
              yesNoAnswer === NO
                ? "enlarged"
                : yesNoAnswer === YES || yesNoAnswer === NA
                ? "hidden"
                : ""
            }
          >
            <Button
              label={<ButtonLabel selected={yesNoAnswer === NO} down />}
              large
              noButton
              onClick={onNoClick}
              answered={yesNoAnswer === NO}
            />

            {yesNoAnswer === NO && (
              <ResetQuestion isMobile={isMobile} no onClick={onResetQuestionClick}>
                <XIcon className='x-icon' />
              </ResetQuestion>
            )}
          </BtnContainer>

          {/* NA BUTTON */}
          {AllowNA && (
            <BtnContainer
              isNA
              className={
                yesNoAnswer === NA
                  ? "enlarged"
                  : yesNoAnswer === NO || yesNoAnswer === YES
                  ? "hidden"
                  : ""
              }
            >
              <Button
                label={<ButtonLabel selected={yesNoAnswer === NA} na />}
                large
                naButton
                onClick={onNaClick}
                answered={yesNoAnswer === NA}
              />

              {yesNoAnswer === NA && (
                <ResetQuestion isMobile={isMobile} onClick={onResetQuestionClick}>
                  <XIcon className='x-icon' />
                </ResetQuestion>
              )}
            </BtnContainer>
          )}

          {/* YES BUTTON */}
          <BtnContainer
            isNA={!!AllowNA}
            className={
              yesNoAnswer === YES
                ? "enlarged"
                : yesNoAnswer === NO || yesNoAnswer === NA
                ? "hidden"
                : ""
            }
          >
            <Button
              label={<ButtonLabel selected={yesNoAnswer === YES} up />}
              large
              yesButton
              onClick={handleYesClick}
              answered={yesNoAnswer === YES}
            />
            {yesNoAnswer === YES && (
              <ResetQuestion isMobile={isMobile} yes onClick={onResetQuestionClick}>
                <XIcon className='x-icon' />
              </ResetQuestion>
            )}
          </BtnContainer>
        </Actions>
      );
  }

  return (
    <Container
      isFocused={isFocused}
      isMobile={isMobile}
      isIOS={isIOS}
      isHeightSet={
        QuestionType === TEXT || QuestionType === NUMERIC || QuestionType === SELECT
      }
    >
      {content}

      <ConfirmationModal
        message={modal.message}
        onClick={onConfirmationClick}
        onClose={closeModal}
        open={open}
        buttonLabel={modal.btnLabel}
      />
    </Container>
  );
}

export default QuestionActions;
