import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import type { Swiper as SwiperType } from "swiper";

import { isMobile } from "utils/utils";
import useMachineProcessInfo from "hooks/useMachineProcessInfo";
import useAssessmentResponse from "hooks/useAssessmentResponse";
import UserContext from "contexts/user-context/UserContext";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import { RelatedItem as RelatedItemType, RelatedCollection } from "types/tasks.types";
import { EntitiesEnum } from "types/types";

import RelatedItemContent from "pages/selected-task-created/components/related-files/components/related-item-content/RelatedItemContent";

import { ReactComponent as ChevronIcon } from "assets/icons/navigation-chevron.svg";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import { StyledLabel } from "styles/generalStyles";
import {
  Container,
  ListWrapper,
  RelatedList,
  RelatedItem,
  Arrow,
} from "styles/related-files.styles";

type RelatedFilesProps = {
  label: string;
};

const { MACHINES, PROCESSES, ASSESSMENTS } = RelatedCollection;
const { MACHINE } = EntitiesEnum;

const swiperPositionInit = {
  isBeginning: true,
  isMiddle: false,
  isEnd: false,
};

function RelatedFiles_Creator({ label }: RelatedFilesProps) {
  const [swiperInstance, setSwiperInstance] = useState<SwiperType>();
  const [swiperPosition, setSwiperPosition] = useState(swiperPositionInit);

  const [relatedItemsList, setRelatedItemsList] = useState<RelatedItemType[]>([]);

  const {
    state: { selectedQuestionnaire, serialNumber: SerialNo },
  } = useContext(QuestionnairesContext);

  const { user } = useContext(UserContext);

  const { assessmentData } = useAssessmentResponse();
  const machineProcessInfo = useMachineProcessInfo();

  const navigate = useNavigate();

  const relatedListLength = relatedItemsList.length;
  const arrowsVisible = !!relatedItemsList ? relatedListLength > 3 : false;
  const leftArrowVisible =
    arrowsVisible && (swiperPosition.isEnd || swiperPosition.isMiddle);
  const rightArrowVisible =
    arrowsVisible && (swiperPosition.isBeginning || swiperPosition.isMiddle);

  // --------------- On click handlers ---------------

  const onForwardArrowClick = () => {
    if (!swiperInstance) return;
    swiperInstance.slideNext();
  };

  const onBackArrowClick = () => {
    if (!swiperInstance) return;
    swiperInstance.slidePrev();
  };

  const onRelatedItemClick = (id: number) => () => {
    navigate(`/task/related/${id}`, { replace: true });
  };

  // --------------- On change handlers ---------------

  const onSlideChange = (e: SwiperType) => {
    const isBeginning = e.activeIndex === 0;
    const isEnd = e.activeIndex === Math.trunc(relatedListLength / 2) + 1;
    const isMiddle = !isBeginning && !isEnd;

    setSwiperPosition({ isBeginning, isMiddle, isEnd });
  };

  useEffect(() => {
    if (!user) return;

    let relatedArray: RelatedItemType[] = [];

    if (machineProcessInfo.entity) {
      const { entity, name, status, Misc } = machineProcessInfo;
      let DepartmentName = "";
      let DepartmentId = "";

      if ("Department" in user && user.Department !== null) {
        DepartmentName = user.Department.DepartmentName ?? "";
        DepartmentId = user.Department.id ?? "";
      } else {
        DepartmentName = user.Departments[0].Departments_id.DepartmentName;
        DepartmentId = user.Departments[0].Departments_id.id;
      }

      const relatedItem: RelatedItemType = {
        id: 101,
        collection: entity === MACHINE ? MACHINES : PROCESSES,
        item: {
          SerialNo,
          id: "item-101",
          MachineModel: name,
          ProcessName: name,
          status,
          Misc,
          Department: {
            DepartmentName: DepartmentName,
            id: DepartmentId,
          },
          LastAssessment: {
            File: "",
          },
        },
      };

      relatedArray = [relatedItem];
    }

    if (assessmentData) {
      const QuestionnaireTitle = selectedQuestionnaire?.QuestionnaireTitle ?? "";
      const QuestionnaireId = selectedQuestionnaire?.id ?? "";

      const relatedItem: RelatedItemType = {
        id: 102,
        collection: ASSESSMENTS,
        item: {
          SerialNo,
          id: "item-102",
          Questionnaire: {
            QuestionnaireTitle,
            id: QuestionnaireId,
          },
        },
      };

      relatedArray = [...relatedArray, relatedItem];
    }

    setRelatedItemsList(relatedArray);
  }, [assessmentData, machineProcessInfo, SerialNo, selectedQuestionnaire, user]);

  useEffect(() => {
    if (!relatedItemsList.length) return;

    sessionStorage.setItem("taskRelated", JSON.stringify(relatedItemsList));
  }, [relatedItemsList]);

  return relatedItemsList.length ? (
    <Container>
      <StyledLabel>{label}</StyledLabel>

      <ListWrapper>
        {leftArrowVisible && (
          <Arrow isMobile={isMobile} back>
            <ChevronIcon className='chevron-icon' onClick={onBackArrowClick} />
          </Arrow>
        )}

        {relatedListLength <= 3 && (
          <RelatedList>
            {relatedItemsList.map((item) => (
              <RelatedItem onClick={onRelatedItemClick(item.id)} key={item.id}>
                <RelatedItemContent relatedItem={item} />
              </RelatedItem>
            ))}
          </RelatedList>
        )}

        {relatedListLength > 3 && (
          <Swiper
            slidesPerView={2}
            spaceBetween={10}
            freeMode={true}
            modules={[FreeMode]}
            onSlideChange={onSlideChange}
            onSwiper={(swiper) => setSwiperInstance(swiper)}
          >
            {relatedItemsList.map((item) => (
              <SwiperSlide key={item.id} onClick={onRelatedItemClick(item.id)}>
                <RelatedItem arrowsVisible>
                  <RelatedItemContent relatedItem={item} />
                </RelatedItem>
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {rightArrowVisible && (
          <Arrow isMobile={isMobile}>
            <ChevronIcon className='chevron-icon forward' onClick={onForwardArrowClick} />
          </Arrow>
        )}
      </ListWrapper>
    </Container>
  ) : null;
}

export default RelatedFiles_Creator;
