import styled, { css } from "styled-components/macro";

export const Container = styled.div`
  margin-top: 1rem;
  height: 8rem;
`;

export const ListWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

export const RelatedList = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const RelatedItem = styled.div<{ arrowsVisible?: boolean }>`
  ${({ arrowsVisible }) =>
    !arrowsVisible &&
    css`
      flex-basis: 50%;
    `}

  ${({ arrowsVisible }) =>
    arrowsVisible &&
    css`
      width: 100%;
    `}
`;

export const Arrow = styled.div<{ isMobile: boolean; back?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 6rem;
  display: flex;
  justify-content: flex-end;
  background-color: #dfe3ed;
  mask-image: linear-gradient(to left, black 35%, transparent);
  z-index: ${({ theme }) => theme.level1};
  animation: arrowFadeIn 0.25s ease-out;

  @keyframes arrowFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  ${({ back }) =>
    back &&
    css`
      left: 0;
      justify-content: flex-start;
      mask-image: linear-gradient(to right, black 35%, transparent);
    `}

  .chevron-icon {
    width: 2.5rem;
    height: 100%;
    cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "none")};
  }

  .forward {
    transform: rotate(180deg);
  }
`;

export const Item = styled.div<{ isMobile: boolean }>`
  border-radius: 0.6rem;
  padding: 0.5rem;
  user-select: none;
  background-color: #fff;
  cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "none")};
`;

export const ItemHeader = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  ${({ theme }) => theme.ellipsis}
`;

export const ItemTitle = styled.div`
  ${({ theme }) => theme.ellipsis}
  font-size: 1.3rem;
  max-width: 16rem;
`;
