import { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks/hooks";
import { CreatedAnswer } from "types/types";

interface AssessmentAnswers {
  assessmentId: string;
  createdAnswers: CreatedAnswer[];
}

const getLocalData = () => {
  const localData = sessionStorage.getItem("assessmentData");

  return !!localData ? JSON.parse(localData) : undefined;
};

function useAssessmentResponse() {
  const [assessmentData, setAssessmentData] = useState<AssessmentAnswers>(getLocalData());
  const { assessmentResponse } = useAppSelector(({ assessment }) => assessment);

  useEffect(() => {
    const { assessment: assessmentId, createdAnswers } = assessmentResponse;

    if (!assessmentId) return;

    setAssessmentData({ assessmentId, createdAnswers });
  }, [assessmentResponse]);

  useEffect(() => {
    if (!assessmentData || !!sessionStorage.getItem("assessmentData")) return;

    sessionStorage.setItem("assessmentData", JSON.stringify(assessmentData));
  }, [assessmentData]);

  return { assessmentData };
}

export default useAssessmentResponse;
