import { instance } from "axios-instance/axios-instance";

import {
  CreateTaskPayload,
  CreateTaskResponse,
  DeleteFilePayload,
  EditTaskPayload,
  EditTaskResponse,
  GetCreatedTasksResponse,
  GetRelatedItemsResponse,
  SendAnswersInProgressPayload,
  UploadFilePayload,
  UploadFileResponse,
} from "types/tasks.types";

export const getTasks = (token: string) => async () => {
  // let path = "/items/Tasks?limit=1000&filter[status][_neq]=archived";
  let path = `/items/Tasks?limit=1000
  &fields[]=id
  &fields[]=AssignTo
  &fields[]=Company
  &fields[]=Deadline
  &fields[]=Description
  &fields[]=Files.id
  &fields[]=Files.directus_files_id.id
  &fields[]=Files.directus_files_id.type
  &fields[]=Files.directus_files_id.title
  &fields[]=Files.directus_files_id.filename_download
  &fields[]=Title
  &fields[]=date_created
  &fields[]=date_updated
  &fields[]=sort
  &fields[]=status
  &fields[]=user_created
  &fields[]=user_updated
  &fields[]=Related.id
  &fields[]=Related.collection
  &sort[]=-date_created
  &filter[_and][1][status][_neq]=archived`;

  const { data } = await instance.get<GetCreatedTasksResponse>(path, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data.data;
};

export const createTask = async (payload: CreateTaskPayload) => {
  const { token, ...body } = payload;

  const { data } = await instance.post<CreateTaskResponse>("/items/Tasks", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const editTask = async (payload: EditTaskPayload) => {
  const { token, id, ...body } = payload;

  const { data } = await instance.patch<EditTaskResponse>(`/items/Tasks/${id}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const uploadTaskFile = async (payload: UploadFilePayload) => {
  const { formData: body, token, onUploadProgress } = payload;

  const { data } = await instance.post<UploadFileResponse>("/files", body, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accepts: "multipart/form-data",
    },
    onUploadProgress,
  });

  return data;
};

export const deleteTaskFiles = async (payload: DeleteFilePayload) => {
  const { fileIds, token } = payload;

  const { data } = await instance.delete<any>(`/files`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: fileIds,
  });

  return data;
};

export const getRelatedItems = (token: string, taskId: string) => async () => {
  let path = `/items/Tasks_Related/?filter[_and][0][Tasks_id]=${taskId}
&fields[]=item:Statements.AccidentForm.Title
&fields[]=item:Statements.AccidentForm.id
&fields[]=item:Statements.id
&fields[]=item:Statements.File
&fields[]=item:Assessments.SerialNo
&fields[]=item:Assessments.id
&fields[]=item:Assessments.Questionnaire.QuestionnaireTitle
&fields[]=item:Assessments.Questionnaire.id
&fields[]=item:Assessments.File
&fields[]=item:Machines.SerialNo
&fields[]=item:Machines.id
&fields[]=item:Machines.MachineModel
&fields[]=item:Machines.Department.DepartmentName
&fields[]=item:Machines.Department.id
&fields[]=item:Machines.LastAssessment.File
&fields[]=item:Machines.status
&fields[]=item:Machines.Misc
&fields[]=item:Processes.SerialNo
&fields[]=item:Processes.id
&fields[]=item:Processes.ProcessName
&fields[]=item:Processes.Department.DepartmentName
&fields[]=item:Processes.LastAssessment
&fields[]=item:Processes.LastAssessment.File
&fields[]=item:Processes.status
&fields[]=item:Processes.Misc
&fields[]=id
&fields[]=collection`;

  const { data } = await instance.get<GetRelatedItemsResponse>(path, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data.data;
};

export const sendAnswersInProgress = async (payload: SendAnswersInProgressPayload) => {
  const { token, ...body } = payload;

  const { data } = await instance.patch<any>("/items/Answers", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};
