import styled, { css } from "styled-components/macro";
import { FilesGallery } from "types/types";

const Container = styled.div<{ moveLeft?: boolean }>`
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  ${({ moveLeft }) =>
    moveLeft &&
    css`
      right: 3.5rem;
    `}
`;

const PhotosNumber = styled.span`
  opacity: 0.8;

  &::before {
    content: "(";
  }
  &::after {
    content: ")";
  }
`;

const Preview = styled.div`
  width: 2rem;
  height: 85%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0.4rem;
`;

function PhotoPreview({
  moveLeft,
  FilesGallery,
}: {
  moveLeft?: boolean;
  FilesGallery: FilesGallery;
}) {
  return !!FilesGallery && FilesGallery.length ? (
    <Container moveLeft={moveLeft}>
      <PhotosNumber>{FilesGallery.length}</PhotosNumber>
      <Preview>
        <Image src={FilesGallery[0].src} alt='' />
      </Preview>
    </Container>
  ) : null;
}

export default PhotoPreview;
