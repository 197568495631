import {
  ChangeEvent,
  useEffect,
  useState,
  useRef,
  FormEvent,
  useMemo,
  useContext,
} from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import { getCookie } from "react-use-cookie";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { isEqual } from "date-fns";

import { deleteTaskFiles, editTask } from "api/tasks";
import { useAppSelector } from "redux/hooks/hooks";
import useModal from "hooks/useModal";

import {
  CreatedTask,
  DeleteFilePayload,
  EditTaskPayload,
  EditTaskResponse,
  TaskFiles,
  TaskStatusOption,
  TaskStatuses,
} from "types/tasks.types";
import { EditModalTypes, ReturnPathKeys } from "types/tasks.types";
import { UserOption } from "types/assign-to-user.types";
import { PermFields } from "types/permissions.types";
import { ACCESS_TOKEN, formatDate, isMobile } from "utils/utils";
import { getStatusOptions } from "utils/tasks-utils";
import { getTranslations as selectedTaskTranslations } from "./translations/selected-task.translations";
import { getTranslations as taskCreatorTranslations } from "pages/task-creator/translations/task-creator.translations";
import { getTranslations as getTasksTranslations } from "pages/user-tasks/translations/tasks.translations";
import { getTranslations } from "translations/select.translations";
import useNotification from "hooks/useNotification";
import useUsersForSelect from "hooks/useUsersForSelect";
import UserContext from "contexts/user-context/UserContext";

import Button from "components/atoms/Button";
import ShimmerLoader from "components/atoms/loaders/ShimmerLoader";
import TaskComment from "./components/task-comment/TaskComment";
import UserCreated from "./components/user-created/UserCreated";
import ErrorAlert from "components/molecules/ErrorAlert";
import ReactionModal from "components/molecules/reaction-modal/ReactionModal";
import ConfirmationModal from "components/organisms/ConfirmationModal";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

import {
  Card,
  Form,
  FormField,
  StyledLabel,
  ContentContainer,
  ButtonContainer,
} from "styles/generalStyles";
import {
  Container,
  Header,
  Title,
  TaskTitle,
  InfoContainer,
  Actions,
  TaskTitleContainer,
  Divider,
} from "styles/selected-task.styles";
import {
  clearStorage,
  getTaskStorage,
  getInitialValueFromStorage,
  getPhotosToBeAttachedFromStorage,
} from "./utils/utils";
import { Keys } from "./types/selected-task.types";

import ConfirmMessage from "./components/confirm-message/ConfirmMessage";
import TaskDeadline from "./components/task-deadline/TaskDeadline";
import RelatedFiles from "./components/related-files/RelatedFiles";
import Attachments from "./components/attachments/Attachments";

import { selectControlStyles } from "styles/select.styles";

const { TITLE: TITLE_MODAL, COMMENT: COMMENT_MODAL } = EditModalTypes;
const { SELECTED_TASK } = ReturnPathKeys;
const { TITLE, DESCRIPTION, STATUS, ASSIGN_TO, DEADLINE } = PermFields;

const {
  INITIAL_STATUS,
  INITIAL_USER_ASSIGNED,
  INITIAL_TITLE,
  INITIAL_DESCRIPTION,
  INITIAL_DEADLINE,
} = Keys;

function SelectedTask() {
  const { allTasks } = useAppSelector(({ tasks }) => tasks);
  const { userOptions, isLoading: usersLoading, isGetUsersError } = useUsersForSelect();

  const [selectedTask, setSelectedTask] = useState<CreatedTask>(getTaskStorage());

  const [selectedStatus, setSelectedStatus] = useState<TaskStatusOption | null>(null);
  const [selectedUser, setSelectedUser] = useState<UserOption | null>(null);
  const [userCreated, setUserCreated] = useState<UserOption | null>(null);

  const [editiTitleValue, setEditTitleValue] = useState("");
  const [editCommentValue, setEditCommentValue] = useState("");

  const { open, openModal, closeModal, secondOpen, openSecondModal, closeSecondModal } =
    useModal();
  const {
    PERMISSIONS: { TASKS_PERMISSIONS },
  } = useContext(UserContext);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const notify = useNotification();

  const {
    i18n: { language },
  } = useTranslation();
  const { label, placeholder, selectNoOptionsMessage, getUsersError } =
    getTranslations(language);
  const { header, labels, notificationMessage, titles } =
    selectedTaskTranslations(language);
  const {
    labels: { title, comment },
    modal,
  } = taskCreatorTranslations(language);
  const { labels: tasksLabels } = getTasksTranslations(language);

  const photosToBeAttached: TaskFiles = getPhotosToBeAttachedFromStorage();
  const newPhotosAdded = !!photosToBeAttached.length;
  const photoDeleted = !!sessionStorage.getItem("photoDeleted");

  // ----- Dirty form handlers -----

  const differentStatus =
    selectedStatus?.id !== getInitialValueFromStorage(INITIAL_STATUS);

  const differentUserAssigned = selectedUser?.id
    ? selectedUser.id !== getInitialValueFromStorage(INITIAL_USER_ASSIGNED)
    : false;

  const differentTitle =
    selectedTask?.Title !== getInitialValueFromStorage(INITIAL_TITLE);

  const differentDescription =
    selectedTask?.Description !== getInitialValueFromStorage(INITIAL_DESCRIPTION);

  const differentDeadline = !isEqual(
    new Date(selectedTask?.Deadline as string),
    new Date(getInitialValueFromStorage(INITIAL_DEADLINE) as string),
  );

  const dirty =
    differentStatus ||
    differentUserAssigned ||
    differentTitle ||
    differentDescription ||
    differentDeadline;

  // *************** PERMISSIONS ***************

  const { PERMITTED_TO_READ, PERMITTED_TO_UPDATE } = TASKS_PERMISSIONS;

  // ----- READ -----
  const permittedToReadAll = PERMITTED_TO_READ.ALL;
  const permittedToReadTitle = permittedToReadAll || PERMITTED_TO_READ.Title;
  const permittedToReadDescription = permittedToReadAll || PERMITTED_TO_READ.Description;
  const permittedToReadStatus = permittedToReadAll || PERMITTED_TO_READ.status;
  const permittedToReadAssignedTo = permittedToReadAll || PERMITTED_TO_READ.AssignTo;
  const permittedToReadUserCreated = permittedToReadAll || PERMITTED_TO_READ.user_created;
  const permittedToReadDeadline = permittedToReadAll || PERMITTED_TO_READ.Deadline;
  const permittedToReadRelated = permittedToReadAll || PERMITTED_TO_READ.Related;

  // ----- UPDATE -----
  const permittedToUpdateAll = PERMITTED_TO_UPDATE.ALL;
  const permittedToEditTitle = permittedToUpdateAll || PERMITTED_TO_UPDATE[TITLE];
  const permittedToEditDescription =
    permittedToUpdateAll || PERMITTED_TO_UPDATE[DESCRIPTION];
  const permittedToEditStatus = permittedToUpdateAll || PERMITTED_TO_UPDATE[STATUS];
  const permittedToEditAssignedTo =
    permittedToUpdateAll || PERMITTED_TO_UPDATE[ASSIGN_TO];
  const permittedToEditDeadline = permittedToUpdateAll || PERMITTED_TO_UPDATE[DEADLINE];

  // ******************************

  const statusOptions = useMemo(() => getStatusOptions(language), [language]);

  const currentEditModalRef = useRef<EditModalTypes>();

  const token = getCookie(ACCESS_TOKEN);
  const taskId = searchParams.get("id") as string;

  const modalType = currentEditModalRef.current;

  const readOnly =
    (modalType === TITLE_MODAL && !permittedToEditTitle) ||
    (modalType === COMMENT_MODAL && !permittedToEditDescription);

  let editValue = "";

  if (modalType === TITLE_MODAL) {
    editValue = editiTitleValue;
  }

  if (modalType === COMMENT_MODAL) {
    editValue = editCommentValue;
  }

  let modalHeader = modalType ? modal.header[modalType] : "";

  let content = null;

  // --------------- Clear state handlers ---------------

  const clearTextValues = () => {
    if (editiTitleValue) {
      setEditTitleValue("");
    }
    if (editCommentValue) {
      setEditCommentValue("");
    }
  };

  // --------------- Navigate handler ---------------

  const navigateToTasksList = () => {
    const path = sessionStorage.getItem(SELECTED_TASK) ?? "";
    navigate(path, { replace: true });
    sessionStorage.removeItem(SELECTED_TASK);
  };

  const navigateToTaskPhotos = () => {
    const path = `/task/selected/photos?slide=0`;
    navigate(path, { replace: true });
  };

  const navigateToTaskDocuments = () => {
    const path = `/task/selected/documents/?id=${selectedTask.id}`;
    navigate(path, { replace: true });
  };

  const handleQuitSelectedTask = () => {
    clearStorage();
    navigateToTasksList();
  };

  // --------------- API handler ---------------

  const onSuccess = () => {
    notify(notificationMessage.success, "success");
    handleQuitSelectedTask();
  };

  const onError = () => {
    notify(notificationMessage.error, "error");
  };

  const { mutate: editSelectedTask, isLoading } = useMutation<
    EditTaskResponse,
    AxiosError,
    EditTaskPayload
  >(editTask, {
    onSuccess,
    onError,
  });

  let disabled = !dirty || usersLoading || isLoading;

  if (newPhotosAdded) {
    disabled = usersLoading || isLoading;
  } else if (!dirty && photoDeleted) {
    disabled = usersLoading || isLoading;
  }

  let btnLabel = labels.btn.save;

  if (!dirty && !newPhotosAdded && photoDeleted) {
    btnLabel = tasksLabels.btn.done;
  }

  const { mutate: deleteFilesFromServer, isLoading: filesDeleting } = useMutation<
    null,
    AxiosError,
    DeleteFilePayload
  >(deleteTaskFiles, {
    onSuccess: () => {},
    onError: () => {},
    onSettled: () => {
      closeSecondModal();
      handleQuitSelectedTask();
    },
  });

  const handleRemoveAttachments = () => {
    const fileIds = photosToBeAttached.map(({ directus_files_id: { id } }) => id);

    deleteFilesFromServer({ token, fileIds });
  };

  // --------------- On click handlers ---------------

  const onCloseIconClick = () => {
    if (dirty || newPhotosAdded) {
      openSecondModal();
    } else {
      handleQuitSelectedTask();
    }
  };

  const onTextFieldClick = (modalType: EditModalTypes) => () => {
    currentEditModalRef.current = modalType;

    if (modalType === TITLE_MODAL) {
      setEditTitleValue(selectedTask.Title);
      openModal();
    }

    if (modalType === COMMENT_MODAL) {
      const description = selectedTask.Description ?? "";

      setEditCommentValue(description);
      openModal();
    }
  };

  const onEditSaveBtnClick = () => {
    const updateSelectedTask = () => {
      if (currentEditModalRef.current === "Title") {
        setSelectedTask((prev) => ({ ...prev, Title: editValue }));
      }

      if (currentEditModalRef.current === "Comment") {
        setSelectedTask((prev) => ({ ...prev, Description: editValue }));
      }
    };

    updateSelectedTask();
    closeModal();
  };

  const onCloseReactionModalIconClick = () => {
    clearTextValues();
    closeModal();
  };

  const onQuitButtonClick = () => {
    if (newPhotosAdded) {
      handleRemoveAttachments();
    } else {
      handleQuitSelectedTask();
    }
  };

  // --------------- On submit handler ---------------

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!dirty && !newPhotosAdded && photoDeleted) {
      handleQuitSelectedTask();
      return;
    }

    let status = selectedStatus?.id as TaskStatuses;
    const id = taskId;

    let payload: EditTaskPayload = {
      id,
      status,
      token,
    };

    if (editiTitleValue) {
      const Title = editiTitleValue;

      payload = { ...payload, Title };
    }

    const Description = selectedTask.Description;

    payload = { ...payload, Description };

    if (selectedUser && selectedUser.id) {
      const AssignTo = selectedUser.id;

      payload = { ...payload, AssignTo };
    }

    if (permittedToEditDeadline) {
      payload = { ...payload, Deadline: selectedTask.Deadline };
    }

    if (newPhotosAdded) {
      payload = { ...payload, Files: { create: photosToBeAttached } };
    }

    editSelectedTask(payload);
  };

  // --------------- On change handlers ---------------

  const onStatusChange = (option: TaskStatusOption | null) => {
    const updateSelectedTask = () => {
      if (option) {
        setSelectedTask((prev) => ({ ...prev, status: option.id }));
      }
    };

    updateSelectedTask();
    setSelectedStatus(option);
  };

  const onEdititTextareaChange = (e: ChangeEvent<HTMLTextAreaElement> | string) => {
    const { current: modalType } = currentEditModalRef;

    if (modalType === TITLE_MODAL) {
      if (typeof e === "string") {
        setEditTitleValue(e);
      } else {
        setEditTitleValue(e.target.value);
      }
    }

    if (modalType === COMMENT_MODAL) {
      if (typeof e === "string") {
        setEditCommentValue(e);
      } else {
        setEditCommentValue(e.target.value);
      }
    }
  };

  const onAssignUserChange = (option: UserOption | null) => {
    if (option) {
      setSelectedTask((prev) => ({ ...prev, AssignTo: option.id }));
    }
  };

  const onTextTranscriptChange = (textTranscript: string) => {
    const { current: modalType } = currentEditModalRef;

    if (modalType === TITLE_MODAL) {
      setEditTitleValue(textTranscript);
    }

    if (modalType === COMMENT_MODAL) {
      setEditCommentValue(textTranscript);
    }
  };

  const onDealineChange = (Deadline: string | null) => {
    setSelectedTask((prev) => ({ ...prev, Deadline }));
  };

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (!allTasks.length || !!selectedTask) return;

    const selected = allTasks.find((task) => task.id === taskId) as CreatedTask;

    if (selected) {
      const { status, AssignTo, Title, Description, Deadline } = selected;

      sessionStorage.setItem(INITIAL_STATUS, JSON.stringify(status));
      sessionStorage.setItem(INITIAL_USER_ASSIGNED, JSON.stringify(AssignTo));
      sessionStorage.setItem(INITIAL_TITLE, JSON.stringify(Title));
      sessionStorage.setItem(INITIAL_DESCRIPTION, JSON.stringify(Description));
      sessionStorage.setItem(INITIAL_DEADLINE, JSON.stringify(Deadline));
    }

    setSelectedTask(selected);
  }, [allTasks, searchParams, taskId, selectedTask]);

  useEffect(() => {
    if (!selectedTask) return;

    sessionStorage.setItem("selectedTask", JSON.stringify(selectedTask));

    const { status, AssignTo, user_created } = selectedTask;

    const initialStatus = statusOptions.find(
      ({ id }) => id === status,
    ) as TaskStatusOption;

    setSelectedStatus(initialStatus);

    if (userOptions && userOptions.length) {
      const userAssigned = userOptions.find(({ id }) => id === AssignTo);
      const createdBy = userOptions.find(({ id }) => id === user_created);

      if (userAssigned) {
        setSelectedUser(userAssigned);
      }

      if (createdBy) {
        setUserCreated(createdBy);
      }
    }
  }, [selectedTask, userOptions, statusOptions]);

  // --------------- Content ---------------

  if (selectedTask) {
    content = (
      <InfoContainer>
        <Form onSubmit={onSubmit}>
          {permittedToReadTitle && (
            <FormField noMargin>
              <StyledLabel>{title}</StyledLabel>
              <TaskTitleContainer
                onClick={onTextFieldClick(TITLE_MODAL)}
                isMobile={isMobile}
                title={!isMobile ? titles.editTitle : ""}
              >
                <TaskTitle readOnly value={selectedTask.Title} />
              </TaskTitleContainer>
            </FormField>
          )}

          {permittedToReadDescription && (
            <FormField noMargin>
              <StyledLabel>{comment}</StyledLabel>
              <TaskComment
                comment={selectedTask.Description}
                onTextFieldClick={onTextFieldClick}
              />
            </FormField>
          )}

          {permittedToReadStatus && (
            <FormField>
              <StyledLabel>Status</StyledLabel>
              <Select
                onChange={onStatusChange}
                options={statusOptions}
                value={selectedStatus}
                isSearchable={false}
                placeholder={placeholder.status}
                isDisabled={!permittedToEditStatus}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    cursor: !isMobile ? "pointer" : "none",
                  }),
                  singleValue: (styles, params) => ({
                    ...styles,
                    color: "#212f5a",
                  }),
                }}
              />
            </FormField>
          )}

          {permittedToReadAssignedTo && (
            <FormField>
              <StyledLabel>{label.assigned}</StyledLabel>

              {userOptions && !isGetUsersError && (
                <Select
                  value={selectedUser}
                  onChange={onAssignUserChange}
                  options={userOptions}
                  isSearchable
                  isDisabled={!permittedToEditAssignedTo}
                  placeholder={placeholder.user}
                  noOptionsMessage={() => <span>{selectNoOptionsMessage}</span>}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      ...selectControlStyles(isGetUsersError),
                      color: "red",
                    }),
                    singleValue: (styles, params) => ({
                      ...styles,
                      color: "#212f5a",
                    }),
                    menu: (base, props) => ({
                      ...base,
                      zIndex: 30,
                    }),
                  }}
                />
              )}

              {usersLoading && <ShimmerLoader />}
              {isGetUsersError && <ErrorAlert message={getUsersError} smallMargin />}
            </FormField>
          )}

          {permittedToReadUserCreated && (
            <UserCreated userCreated={userCreated} isLoading={usersLoading} />
          )}

          <TaskDeadline
            permittedToReadDeadline={permittedToReadDeadline}
            permittedToEditDeadline={permittedToEditDeadline}
            taskDeadline={selectedTask.Deadline}
            onDealineChange={onDealineChange}
          />

          <RelatedFiles
            task={selectedTask}
            label={tasksLabels.related}
            permittedToReadRelated={permittedToReadRelated}
          />

          <Attachments
            files={selectedTask.Files}
            label={tasksLabels.attachments}
            navigateToTaskPhotos={navigateToTaskPhotos}
            navigateToTaskDocuments={navigateToTaskDocuments}
          />

          <Actions>
            <ButtonContainer>
              <Button
                label={btnLabel}
                loading={isLoading}
                disabled={disabled}
                type='submit'
                smallFont
              />
            </ButtonContainer>
          </Actions>
        </Form>
      </InfoContainer>
    );
  }

  return (
    <ContentContainer>
      <Divider />
      <Card noBottomSpace>
        <Header isMobile={isMobile} noUnderline noPadding>
          <Title>
            {!!selectedTask && (
              <span>
                {header.title}&nbsp;::&nbsp;
                {formatDate(selectedTask.date_created)}
              </span>
            )}
          </Title>
          <CloseIcon className='close-icon' onClick={onCloseIconClick} />
        </Header>

        <Container>{content}</Container>

        <ReactionModal
          header={modalHeader}
          open={open}
          onClose={onCloseReactionModalIconClick}
          reactionTextAreaValue={editValue}
          onReactionTextAreaChange={onEdititTextareaChange}
          onTextTranscriptChange={onTextTranscriptChange}
          onSaveReactionBtnClick={onEditSaveBtnClick}
          optional={modalType === COMMENT_MODAL}
          readOnly={readOnly}
        />

        <ConfirmationModal
          message={<ConfirmMessage filesDeleting={filesDeleting} />}
          onClick={onQuitButtonClick}
          onClose={() => {
            if (filesDeleting) return;
            closeSecondModal();
          }}
          open={secondOpen}
          buttonLabel={labels.btn.quit}
          buttonMiddle
          loading={filesDeleting}
          closeDisabled={filesDeleting}
        />
      </Card>
    </ContentContainer>
  );
}

export default SelectedTask;
