import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCookie } from "react-use-cookie";
import { useQuery } from "react-query";

import { isMobile, ACCESS_TOKEN } from "utils/utils";
import { getTasks } from "api/tasks";
import { ReturnPathKeys, CreatedTask } from "types/tasks.types";
import { TabsEnum } from "../../types/user-tasks.types";
import { getTranslations } from "../../translations/tasks.translations";
import {
  getTabsVisibleFlag,
  sortByDateDesc,
  clearSessionStorage,
} from "./utils/tasks-handler.utils";
import UserContext from "contexts/user-context/UserContext";
import { useAppDispatch, useAppSelector } from "redux/hooks/hooks";
import { setAllTasks } from "redux/slices/tasksSlice";

import Button from "components/atoms/Button";
import NavTab from "../nav-tab/NavTab";
import TasksList from "./components/tasks-list/TasksList";

import {
  Container,
  TasksListContainer,
  TasksActions,
  ButtonContainer,
} from "../../styles/tasks.styles";
import { Wrapper } from "styles/selected-task.styles";

type TasksCreatedProps = {
  setTasksNumber: Dispatch<SetStateAction<number>>;
};

const { SELECTED_TASK, TASK_CREATOR } = ReturnPathKeys;
const { OTHER, ASSIGNED } = TabsEnum;

function TasksHandler({ setTasksNumber }: TasksCreatedProps) {
  const { allTasks } = useAppSelector(({ tasks }) => tasks);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [tasksAssigned, setTasksAssigned] = useState<CreatedTask[]>([]);
  const [tasksOther, setTasksOther] = useState<CreatedTask[]>([]);
  const [tabsBlocked, setTabsBlocked] = useState(false);

  const {
    i18n: { language },
  } = useTranslation();
  const { labels } = getTranslations(language);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get("tab") as TabsEnum;

  const {
    user,
    PERMISSIONS: { TASKS_PERMISSIONS },
  } = useContext(UserContext);

  const { PERMITTED_TO_CREATE } = TASKS_PERMISSIONS;
  const hasPermissionToCreateTask = PERMITTED_TO_CREATE.ALL;

  const token = getCookie(ACCESS_TOKEN);

  const tabsVisible = getTabsVisibleFlag(user);

  // --------------- Tasks list content ---------------

  let tasksList: CreatedTask[] = allTasks;

  tasksList = currentTab === ASSIGNED ? tasksAssigned : tasksOther;

  // --------------- Tasks handler ---------------

  const handleTasks = (tasks: CreatedTask[]) => {
    const assignedTasks = tasks.filter(({ AssignTo }) => AssignTo === user?.id);
    const otherTasks = tasks.filter(({ AssignTo }) => AssignTo !== user?.id);

    setTasksAssigned(assignedTasks);
    setTasksOther(otherTasks);
  };

  // --------------- API handler ---------------

  const onSuccess = (data: CreatedTask[]) => {
    const sortedData = sortByDateDesc(data);

    dispatch(setAllTasks(sortedData));
    handleTasks(sortedData);
  };

  const { isLoading, isError, refetch, isRefetching } = useQuery(
    "tasks",
    getTasks(token),
    {
      onSuccess,
      keepPreviousData: false,
    },
  );

  // --------------- Effect handlers ---------------

  useEffect(() => {
    refetch();
    clearSessionStorage();
  }, [currentTab, refetch]);

  useEffect(() => {
    setTabsBlocked(isLoading);
  }, [isLoading, setTabsBlocked]);

  useEffect(() => {
    if (currentTab === ASSIGNED) {
      setTasksNumber(tasksAssigned.length);
    }

    if (currentTab === OTHER) {
      setTasksNumber(tasksOther.length);
    }
  }, [
    currentTab,
    setTasksNumber,
    tasksAssigned.length,
    tasksOther.length,
    allTasks.length,
  ]);

  // --------------- Navigation handlers ---------------

  const handleReturnPath = (key: ReturnPathKeys) => {
    const tab = currentTab === ASSIGNED ? "assigned" : "other";
    sessionStorage.setItem(key, `/tasks?tab=${tab}`);
  };

  const navigateToSeletedTaskPage = (id: string) => {
    navigate(`/task/selected?id=${id}`);
    handleReturnPath(SELECTED_TASK);
  };

  const navigateToTaskCreator = () => {
    navigate("/task-creator");
    handleReturnPath(TASK_CREATOR);
  };

  // --------------- On click handlers ---------------

  const onItemClick = (id: string) => () => {
    navigateToSeletedTaskPage(id);
  };

  const onAddTaskButtonClick = () => {
    navigateToTaskCreator();
  };

  const onTabClick = (tab: TabsEnum) => {
    if (tabsBlocked) return;

    setSearchParams({ tab }, { replace: true });
  };

  return (
    <Container>
      <Wrapper isMobile={isMobile}>
        <TasksListContainer>
          <TasksList
            tasksList={tasksList}
            onItemClick={onItemClick}
            isLoading={isLoading}
            isRefetching={isRefetching}
            isError={isError}
          />
        </TasksListContainer>

        {
          <TasksActions>
            {tabsVisible && (
              <>
                <NavTab
                  name={ASSIGNED}
                  onTabClick={onTabClick}
                  selected={currentTab === ASSIGNED}
                />
                <NavTab
                  name={OTHER}
                  onTabClick={onTabClick}
                  selected={currentTab === OTHER}
                />
              </>
            )}
            {hasPermissionToCreateTask && (
              <ButtonContainer>
                <Button
                  label={labels.btn.add}
                  customClass='add-btn'
                  onClick={onAddTaskButtonClick}
                />
              </ButtonContainer>
            )}
          </TasksActions>
        }
      </Wrapper>
    </Container>
  );
}

export default TasksHandler;
