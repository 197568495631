import styled, { css } from "styled-components/macro";

export const Container = styled.div`
  ${({ theme }) => theme.fillUpRemainingSpace};
  gap: 1rem;
  background-color: rgba(196, 205, 222, 0.25);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(17, 20, 22, 0.15) 0px 0px 0px 1px;
  padding: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 0.6rem;

  overflow-y: auto;
  overflow-x: hidden;
`;

export const GalleryContainer = styled.div<{
  isMobile: boolean;
  isRightArrowHidden?: boolean;
  isLeftArrowHidden?: boolean;
}>`
  flex-basis: 95%;

  .swiper {
    position: relative;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
  }

  .swiper-slide {
    ${({ theme }) => theme.fillUpRemainingSpace};
  }

  .preview-image {
    width: 77%;

    display: inline-block;
    margin: 0 auto;
    transition: width 0.2s linear;

    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;

    user-select: none;

    @media (min-height: 617px) {
      width: 100%;
    }
  }

  .swiper-pagination-progressbar-fill {
    background-color: ${({ theme }) => theme.white};
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: ${({ theme }) => theme.white};
    text-shadow: 0px 1px 2px rgba(66, 68, 90, 1);
    cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};

    &::after {
      font-size: 3.5rem;

      @media screen and (min-height: 616px) {
        font-size: 4.4rem;
      }
    }

    @media screen and (min-height: 616px) {
      font-size: 4.4rem;
    }
  }

  .swiper-button-prev {
    visibility: ${({ isLeftArrowHidden }) => (isLeftArrowHidden ? "hidden" : "visible")};
  }

  .swiper-button-next {
    visibility: ${({ isRightArrowHidden }) =>
      isRightArrowHidden ? "hidden" : "visible"};
  }
`;

export const BinContainer = styled.div<{ isMobile: boolean }>`
  width: 4.2rem;
  height: 4.2rem;
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  ${({ theme }) => theme.iconBackground};
  z-index: ${({ theme }) => theme.level1};
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};

  .bin {
    width: 2.5rem;
    height: 2.5rem;

    path {
      stroke: ${({ theme }) => theme.white};
    }

    z-index: ${({ theme }) => theme.level1};
  }
`;

export const PhotoCounter = styled.div`
  position: absolute;
  top: 1.4rem;
  left: 0.8rem;
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: ${({ theme }) => theme.level1};
  color: ${({ theme }) => theme.textColor1};
  border-radius: 0.4rem;
  user-select: none;
  cursor: default;
`;

export const Placeholder = styled.div`
  font-size: 1.6rem;
  text-align: center;
  color: rgb(33, 47, 90);
  margin-top: 4rem;
  font-style: italic;
  opacity: 0.7;
`;

export const Done = styled.span<{ isMobile: boolean; disabled: boolean }>`
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
  font-weight: 500;
  background-color: ${({ theme }) => theme.buttonColorNeutral};
  color: ${({ theme }) => theme.textColor1};
  padding: 0.75rem 1.25rem;
  border-radius: 0.6rem;
  ${({ theme }) => theme.cardShadow};

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`;
