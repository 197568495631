import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import type { Swiper as SwiperType } from "swiper";

import useRelatedFiles from "hooks/useRelatedFiles";
import { CreatedTask } from "types/tasks.types";
import { isMobile } from "utils/utils";

import { ReactComponent as ChevronIcon } from "assets/icons/navigation-chevron.svg";

import RelatedItemContent from "./components/related-item-content/RelatedItemContent";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import { StyledLabel } from "styles/generalStyles";
import {
  Container,
  ListWrapper,
  RelatedList,
  RelatedItem,
  Arrow,
} from "styles/related-files.styles";

type RelatedFilesProps = {
  task: CreatedTask;
  label: string;
  permittedToReadRelated: boolean;
};

const swiperPositionInit = {
  isBeginning: true,
  isMiddle: false,
  isEnd: false,
};

function RelatedFiles({ task, label, permittedToReadRelated }: RelatedFilesProps) {
  const { relatedList } = useRelatedFiles(task);

  const [swiperInstance, setSwiperInstance] = useState<SwiperType>();
  const [swiperPosition, setSwiperPosition] = useState(swiperPositionInit);

  const navigate = useNavigate();

  const relatedListLength = relatedList.length;
  const arrowsVisible = relatedList ? relatedListLength > 3 : false;
  const leftArrowVisible =
    arrowsVisible && (swiperPosition.isEnd || swiperPosition.isMiddle);
  const rightArrowVisible =
    arrowsVisible && (swiperPosition.isBeginning || swiperPosition.isMiddle);

  // --------------- On click handlers ---------------

  const onForwardArrowClick = () => {
    if (!swiperInstance) return;
    swiperInstance.slideNext();
  };

  const onBackArrowClick = () => {
    if (!swiperInstance) return;
    swiperInstance.slidePrev();
  };

  const onRelatedItemClick = (id: number) => () => {
    navigate(`/task/related/${id}`, { replace: true });
  };

  // --------------- On change handlers ---------------

  const onSlideChange = (e: SwiperType) => {
    const isBeginning = e.activeIndex === 0;
    const isEnd = e.activeIndex === Math.trunc(relatedListLength / 2) + 1;
    const isMiddle = !isBeginning && !isEnd;

    setSwiperPosition({ isBeginning, isMiddle, isEnd });
  };

  if (!permittedToReadRelated) {
    return null;
  }

  return !!relatedList?.length ? (
    <Container>
      <StyledLabel>{label}</StyledLabel>

      <ListWrapper>
        {leftArrowVisible && (
          <Arrow isMobile={isMobile} back>
            <ChevronIcon className='chevron-icon' onClick={onBackArrowClick} />
          </Arrow>
        )}

        {relatedListLength <= 3 && (
          <RelatedList>
            {relatedList.map((item) => (
              <RelatedItem onClick={onRelatedItemClick(item.id)} key={item.id}>
                <RelatedItemContent relatedItem={item} />
              </RelatedItem>
            ))}
          </RelatedList>
        )}

        {relatedListLength > 3 && (
          <Swiper
            slidesPerView={2}
            spaceBetween={10}
            freeMode={true}
            modules={[FreeMode]}
            onSlideChange={onSlideChange}
            onSwiper={(swiper) => setSwiperInstance(swiper)}
          >
            {relatedList.map((item) => (
              <SwiperSlide key={item.id} onClick={onRelatedItemClick(item.id)}>
                <RelatedItem arrowsVisible>
                  <RelatedItemContent relatedItem={item} />
                </RelatedItem>
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {rightArrowVisible && (
          <Arrow isMobile={isMobile}>
            <ChevronIcon className='chevron-icon forward' onClick={onForwardArrowClick} />
          </Arrow>
        )}
      </ListWrapper>
    </Container>
  ) : null;
}

export default RelatedFiles;
