import styled from "styled-components/macro";

import { isMobile } from "utils/utils";

import { ReactComponent as FullScreen } from "assets/icons/fullscreen.svg";

const Container = styled.a<{ isMobile: boolean }>`
  display: block;
  width: 3.5rem;
  height: 3.5rem;
  position: absolute;
  bottom: 1.3rem;
  right: 1.6rem;
  z-index: ${({ theme }) => theme.level1};
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
  ${({ theme }) => theme.iconBackground};
`;

const IconContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  .fullscreen-icon {
    width: 90%;
    height: 90%;
  }
`;

function FullScreenIcon({ href }: { href: string }) {
  return (
    <Container href={href} rel='noreferrer' isMobile={isMobile}>
      <IconContainer>
        <FullScreen className='fullscreen-icon' />
      </IconContainer>
    </Container>
  );
}

export default FullScreenIcon;
