import { ReturnPathKeys } from "types/tasks.types";
import { CountryCodes, EntitiesEnum, Entity } from "types/types";

const { CHANGE_STATUS, TASK_CREATOR } = ReturnPathKeys;
const { MACHINE } = EntitiesEnum;

type DataType = {
  question: string;
  path: string;
  returnPath: {
    key: ReturnPathKeys | string;
    value: string;
  };
};

export const getTranslations = (
  language: string,
  entity: Entity,
  hasPermissionToUpdateStatus: boolean,
  hasPermissionToCreateTask: boolean,
) => {
  const isPolish = language.includes(CountryCodes.PL);

  let entityName = entity === MACHINE ? "machine" : "process";

  let header = { title: "Additional Options" };

  let button = {
    back: "Back",
    skip: "Skip",
    change: "Change",
    create: "Create",
    exit: "Exit",
  };

  let warning = {
    top: "No machine/process data.",
    bottom: "Status change not available.",
  };

  let data: DataType[] = [
    {
      question: `Do you want to change ${entityName} status?`,
      path: "/status-change",
      returnPath: {
        key: CHANGE_STATUS,
        value: "/additional-options?step=2",
      },
    },
    {
      question: "Do you want to create a task?",
      path: "/task-creator",
      returnPath: {
        key: TASK_CREATOR,
        value: "/additional-options?step=3",
      },
    },
    {
      question: "Exit to home page.",
      path: "/home",
      returnPath: {
        key: "",
        value: "",
      },
    },
  ];

  if (!hasPermissionToUpdateStatus) {
    data = data
      .filter(({ path }) => !path.includes("/status-change"))
      .reduce((acc: DataType[], curr: DataType) => {
        if (curr.path.includes("/task-creator")) {
          acc = [
            ...acc,
            {
              ...curr,
              returnPath: {
                key: curr.returnPath.key,
                value: "/additional-options?step=2",
              },
            },
          ];
        } else {
          acc = [...acc, curr];
        }

        return acc;
      }, []);
  }

  if (!hasPermissionToCreateTask) {
    data = data
      .filter(({ path }) => !path.includes("/task-creator"))
      .reduce((acc: DataType[], curr: DataType) => {
        if (curr.path.includes("/status-change")) {
          acc = [
            ...acc,
            {
              ...curr,
              returnPath: {
                key: curr.returnPath.key,
                value: "/additional-options?step=2",
              },
            },
          ];
        } else {
          acc = [...acc, curr];
        }

        return acc;
      }, []);
  }

  // ----- POLISH -----

  if (isPolish) {
    entityName = entity === MACHINE ? "maszyny" : "procesu";
    header = { title: "Opcje Dodatkowe" };

    button = {
      back: "Wstecz",
      skip: "Pomiń",
      change: "Zmień",
      create: "Utwórz",
      exit: "Zakończ",
    };

    warning = {
      top: "Brak danych o maszynie/procesie.",
      bottom: "Zmiana stausu niedostępna.",
    };

    data = [
      {
        question: `Czy chcesz zmienić status ${entityName}?`,
        path: "/status-change",
        returnPath: {
          key: CHANGE_STATUS,
          value: "/additional-options?step=2",
        },
      },
      {
        question: "Czy chcesz utworzyć zadanie?",
        path: "/task-creator",
        returnPath: {
          key: TASK_CREATOR,
          value: "/additional-options?step=3",
        },
      },
      {
        question: "Zakończ i wróć do strony głównej.",
        path: "/home",
        returnPath: {
          key: "",
          value: "",
        },
      },
    ];

    if (!hasPermissionToUpdateStatus) {
      data = data
        .filter(({ path }) => !path.includes("/status-change"))
        .reduce((acc: DataType[], curr: DataType) => {
          if (curr.path.includes("/task-creator")) {
            acc = [
              ...acc,
              {
                ...curr,
                returnPath: {
                  key: curr.returnPath.key,
                  value: "/additional-options?step=2",
                },
              },
            ];
          } else {
            acc = [...acc, curr];
          }

          return acc;
        }, []);
    }

    if (!hasPermissionToCreateTask) {
      data = data
        .filter(({ path }) => !path.includes("/task-creator"))
        .reduce((acc: DataType[], curr: DataType) => {
          if (curr.path.includes("/status-change")) {
            acc = [
              ...acc,
              {
                ...curr,
                returnPath: {
                  key: curr.returnPath.key,
                  value: "/additional-options?step=2",
                },
              },
            ];
          } else {
            acc = [...acc, curr];
          }

          return acc;
        }, []);
    }
  }

  return { data, header, button, warning };
};
