import { useEffect, useState, MouseEvent, useRef, Dispatch, SetStateAction } from "react";
import { AxiosError } from "axios";
import { useQuery, useMutation } from "react-query";
import { getCookie } from "react-use-cookie";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useNotification from "hooks/useNotification";
import { getDepartments } from "api/companies";
import { assignDepartmentToUser } from "api/user";
import {
  AssignDepartmentToUserPayload,
  DepartmentItem,
  DepartmentsResponse,
  DeptStatus,
  User,
} from "types/types";
import { ACCESS_TOKEN, isMobile } from "utils/utils";
import { checkIfContainsArchived, compareArrays } from "./utils/departments-edit.utils";
import { getTranslations } from "../../translations/profile.translations";

import BackArrow from "components/atoms/BackArrow";
import Button from "components/atoms/Button";
import CheckboxIcon from "components/atoms/checkbox-icon/CheckboxIcon";
import ErrorAlert from "components/molecules/ErrorAlert";
import Placeholders from "./components/placeholders/Placeholders";

import {
  Container,
  Content,
  Header,
  HeaderTitle,
  DepartmentsList,
  DepartmentsListItem,
  DeptNameContainer,
  DeptName,
  Actions,
  ButtonContainer,
  ArchivedSwitchContainer,
  ArchivedSwitch,
  ArchivedLabel,
} from "./styles/department-edit.styles";

const { ARCHIVED } = DeptStatus;

type DepartmentsEditProps = {
  user: User | undefined;
  isVisible: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  refetchUserInfo: () => void;
  onBackArrowClick: () => void;
  fetchAccidentForms: () => void;
};

function DepartmentsEdit({
  user,
  isVisible,
  setIsDirty,
  refetchUserInfo,
  onBackArrowClick,
  fetchAccidentForms,
}: DepartmentsEditProps) {
  const [departments, setDepartments] = useState<DepartmentItem[]>([]);
  const [assignedDepartments, setAssignedDepartments] = useState<string[]>([]);
  const [departmentsToBeDeleted, setDepartmentsToBeDeleted] = useState<string[]>([]);
  const [archivedVisible, setArchivedVisible] = useState(false);
  const initialAssignedDepts = useRef<string[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const token = getCookie(ACCESS_TOKEN);
  const {
    i18n: { language },
  } = useTranslation();
  const {
    departments: { labels, notification, errorAlert },
  } = getTranslations(language);
  const notify = useNotification();

  const sameArrays = compareArrays(assignedDepartments, initialAssignedDepts.current);
  const containerHeight = Number(searchParams.get("containerHeight") as string);

  const { data, isLoading, isSuccess, isError } = useQuery<
    DepartmentsResponse,
    AxiosError
  >("departments", getDepartments(token), {
    keepPreviousData: false,
    enabled: isVisible,
  });

  const containsArchived = checkIfContainsArchived(data);

  const { mutate, isLoading: assingDeptLoading } = useMutation<
    any,
    AxiosError,
    AssignDepartmentToUserPayload
  >(assignDepartmentToUser, {
    onSuccess: () => {
      const containerHeight = searchParams.get("containerHeight") as string;
      setSearchParams({ containerHeight });
      refetchUserInfo();
      fetchAccidentForms();
      notify(notification.assignDept.success, "success");
      setIsDirty(false);

      if (departmentsToBeDeleted.length) {
        setDepartmentsToBeDeleted([]);
      }
    },
    onError: () => {
      notify(notification.assignDept.error, "error");
    },
  });

  // --------------- On click handlers ---------------

  const onSaveBtnClick = () => {
    if (!user) return;

    const create = assignedDepartments
      .filter((item) => !initialAssignedDepts.current.includes(item))
      .map((item) => ({
        directus_users_id: user.id,
        Departments_id: { id: item },
      }));

    const deleted = user.Departments.reduce((acc: number[], curr) => {
      if (departmentsToBeDeleted.includes(curr.Departments_id.id)) {
        acc = [...acc, curr.id];
      }

      return acc;
    }, []);

    const payload: AssignDepartmentToUserPayload = {
      token,
      userId: user.id,
      Departments: {
        create,
        update: [],
        delete: deleted,
      },
    };

    mutate(payload);
  };

  const onDepartmentItemClick = (e: MouseEvent<HTMLLIElement>) => {
    const selectedDept = e.currentTarget.id;

    if (assignedDepartments.includes(selectedDept)) {
      setAssignedDepartments((prev) => prev.filter((dept) => dept !== selectedDept));
    } else {
      setAssignedDepartments((prev) => [...prev, selectedDept]);
    }

    if (initialAssignedDepts.current.includes(selectedDept)) {
      if (departmentsToBeDeleted.includes(selectedDept)) {
        setDepartmentsToBeDeleted((prev) => prev.filter((dept) => dept !== selectedDept));
      } else {
        setDepartmentsToBeDeleted((prev) => [...prev, selectedDept]);
      }
    }
  };

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (!user || !isVisible) return;

    const deptsAssigned = user.Departments.map(({ Departments_id }) =>
      Departments_id ? Departments_id.id : "null",
    ).filter((item) => item !== "null"); // In some cases (archived in particular) Departments_id contains null, hence the filtering

    setArchivedVisible(false);
    setAssignedDepartments(deptsAssigned);
    initialAssignedDepts.current = deptsAssigned;
  }, [user, isVisible]);

  useEffect(() => {
    if (!data) return;

    let deptsList = data.data;

    if (!archivedVisible) {
      deptsList = deptsList.filter(({ status }) => status !== ARCHIVED);
    }

    setDepartments(deptsList);
  }, [data, archivedVisible]);

  useEffect(() => {
    setIsDirty(!sameArrays);
  }, [sameArrays, setIsDirty]);

  return isVisible ? (
    <Container containerHeight={containerHeight}>
      <Content>
        <Header>
          <BackArrow customClass='arrow-back' onClick={onBackArrowClick} />
          <HeaderTitle>{labels.header}</HeaderTitle>
        </Header>
        {!!departments.length && (
          <DepartmentsList>
            {departments.map(({ id, DepartmentName, status }) => (
              <DepartmentsListItem
                key={id}
                id={id}
                isMobile={isMobile}
                onClick={onDepartmentItemClick}
              >
                <DeptNameContainer>
                  <DeptName archived={status === ARCHIVED}>{DepartmentName}</DeptName>
                </DeptNameContainer>
                <CheckboxIcon
                  checked={assignedDepartments.includes(id)}
                  archived={status === ARCHIVED}
                />
              </DepartmentsListItem>
            ))}
          </DepartmentsList>
        )}

        {isLoading && <Placeholders />}

        {isError && <ErrorAlert message={errorAlert.message} smallMargin />}
      </Content>

      {isSuccess && (
        <Actions spaceBetween={containsArchived}>
          {containsArchived && (
            <ArchivedSwitchContainer>
              <ArchivedSwitch
                isMobile={isMobile}
                onClick={() => {
                  setArchivedVisible((prev) => !prev);
                }}
              >
                <CheckboxIcon checked={archivedVisible} small />
                <ArchivedLabel>{labels.archived}</ArchivedLabel>
              </ArchivedSwitch>
            </ArchivedSwitchContainer>
          )}
          <ButtonContainer>
            <Button
              label={labels.saveBtn}
              question
              disabled={
                sameArrays ||
                isLoading ||
                assingDeptLoading ||
                !assignedDepartments.length
              }
              loading={assingDeptLoading}
              customClass='save-btn'
              onClick={onSaveBtnClick}
            />
          </ButtonContainer>
        </Actions>
      )}
    </Container>
  ) : null;
}

export default DepartmentsEdit;
