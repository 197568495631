import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SendQuestionnaireResponse } from "types/types";

interface InitialState {
  assessmentResponse: SendQuestionnaireResponse;
}

const initialState: InitialState = {
  assessmentResponse: {
    answers: [],
    assessment: "",
    createdAnswers: [],
  },
};

export const assessmentSlice = createSlice({
  name: "assessmentSlice",
  initialState,
  reducers: {
    setAssessmentResponse(state, action: PayloadAction<SendQuestionnaireResponse>) {
      return { ...state, assessmentResponse: action.payload };
    },
  },
});

export const { setAssessmentResponse } = assessmentSlice.actions;
