import { ChangeEvent, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Pagination, Navigation } from "swiper";
import type { Swiper as SwiperType } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCookie } from "react-use-cookie";
import { useMutation } from "react-query";
import { AxiosError, AxiosProgressEvent } from "axios";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { ACCESS_TOKEN, isMobile } from "utils/utils";
import { getCurrentSlide } from "pages/task-creator/utils/task-creator.utils";
import { deleteTaskFiles, editTask, uploadTaskFile } from "api/tasks";
import useModal from "hooks/useModal";
import useDevices from "hooks/useDevices";
import useNotification from "hooks/useNotification";
import useTaskAttachments from "hooks/useTaskAttachments";

import {
  CreatedTask,
  DirectusFile,
  UploadFilePayload,
  UploadFileResponse,
  TaskPhotoObject,
  EditTaskResponse,
  EditTaskPayload,
  DeleteFilePayload,
} from "types/tasks.types";
import { ImageSize } from "types/types";

import { getTranslations } from "pages/user-tasks/translations/tasks.translations";
import { getTranslations as selectedTaskTranslations } from "pages/selected-task-created/translations/selected-task.translations";

import { ReactComponent as BinIcon } from "assets/icons/bin.svg";

import BackArrow from "components/atoms/BackArrow";
import LastSlide from "pages/task-photos/components/last-slide/LastSlide";
import PreviewImage from "pages/task-photos/components/preview-image/PreviewImage";
import PhotoLoader from "pages/task-photos/components/photo-loader/PhotoLoader";
import ConfirmationModal from "components/organisms/ConfirmationModal";

import { ContentContainer } from "styles/generalStyles";
import {
  Container,
  GalleryContainer,
  PhotoCounter,
  BinContainer,
  Done,
} from "pages/task-photos/styles/task-photos.styles";
import { Header, Actions } from "pages/task-creator/styles/task-creator.styles";
import { getPhotosToBeAttachedFromStorage } from "pages/selected-task-created/utils/utils";
import FullScreenIcon from "components/atoms/full-screen-icon/FullScreenIcon";

type Task = { id: string; files: DirectusFile[] };

const getSelectedTaskFromStorage = () => {
  const localData = sessionStorage.getItem("selectedTask");
  const selectedTask: CreatedTask = localData ? JSON.parse(localData) : null;

  return selectedTask;
};

const getTaskFromStorage = () => {
  const selectedTask: CreatedTask = getSelectedTaskFromStorage();

  return selectedTask
    ? {
        id: selectedTask.id,
        files: selectedTask.Files.filter((item) => !!item.directus_files_id),
      }
    : { id: "", files: [] };
};

const lastSlideSizeInitial = { width: 0, height: 0 };

function SelectedTaskPhotos() {
  const [task, setTask] = useState<Task>(getTaskFromStorage());
  const [lastSlideSize, setLastSlideSize] = useState<ImageSize>(lastSlideSizeInitial);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const { open, openModal, closeModal } = useModal();
  const { taskPhotosObjects } = useTaskAttachments(task.files);

  const updatedTaskPhotosObjects = useRef<TaskPhotoObject[]>([]);
  const photoToBeDeletedId = useRef("");
  const taskRelationIdRef = useRef<number | undefined>(undefined);

  const {
    i18n: { language },
  } = useTranslation();
  const { header } = selectedTaskTranslations(language);
  const { alert, labels, modal } = getTranslations(language);
  const navigate = useNavigate();

  const photoToBeUploadedRef = useRef<File>();

  const { isVideoInput } = useDevices();
  const notify = useNotification();

  const token = getCookie(ACCESS_TOKEN);

  const canTakePhotos = isMobile && isVideoInput;

  const currentSlide = getCurrentSlide(searchParams);
  const isLastSlide = currentSlide === taskPhotosObjects.length;

  const udpateTaskAfterUpload = (directus_files_id: DirectusFile) => {
    // --- 1. Update task in component state ---
    setTask((prev) => ({ ...prev, files: [...prev.files, directus_files_id] }));

    // --- 2. Update selectedTask in sessionStorage ---
    const selectedTask = getSelectedTaskFromStorage();
    const updatedTask: CreatedTask = {
      ...selectedTask,
      Files: [...selectedTask.Files, directus_files_id],
    };
    sessionStorage.setItem("selectedTask", JSON.stringify(updatedTask));
  };

  const updatePhotosToBeAttached = (id: string) => {
    const localData = sessionStorage.getItem("photosToBeAttached");
    const photosToBeAttached = !!localData ? JSON.parse(localData) : [];
    const photosToBeAttachedUpdated = [
      ...photosToBeAttached,
      { directus_files_id: { id }, Tasks_id: task.id },
    ];

    sessionStorage.setItem(
      "photosToBeAttached",
      JSON.stringify(photosToBeAttachedUpdated),
    );
  };

  const showUploadPhotoSuccessAlert = () => {
    notify(alert.success.photoDeleted, "success");
  };

  // --------------- Mutation handlers ---------------

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    const value = Math.ceil((progressEvent.progress as number) * 100);

    setUploadProgress(value);
  };

  const onUploadPhotoSuccess = ({ data }: UploadFileResponse) => {
    const { filename_download, id, title, type } = data;

    udpateTaskAfterUpload({ directus_files_id: { filename_download, id, title, type } });
    updatePhotosToBeAttached(id);

    notify(alert.success.photoAttached, "success");
  };

  // --------------- Mutations ---------------

  const { mutate: uploadPhoto, isLoading: photoUploading } = useMutation<
    UploadFileResponse,
    AxiosError,
    UploadFilePayload
  >(uploadTaskFile, {
    onSuccess: onUploadPhotoSuccess,
    onError: (err: AxiosError) => {
      console.error(err);
      notify(alert.error.failedToAttachPhoto, "error");
    },
  });

  const { mutate: unlinkPhotoFromTask, isLoading: unlikingPhoto } = useMutation<
    EditTaskResponse,
    AxiosError,
    EditTaskPayload
  >(editTask, {
    onSuccess: () => {
      showUploadPhotoSuccessAlert();
      taskRelationIdRef.current = undefined;
    },
    onError: () => {
      notify(alert.error.failedToDletePhoto, "error");
    },
  });

  const { mutate: deletePhotoFromServer, isLoading: deletingPhoto } = useMutation<
    null,
    AxiosError,
    DeleteFilePayload
  >(deleteTaskFiles, {
    onSuccess: () => {
      // Update task in state
      const updatedTaskFiles = task.files.filter(
        (item) => item.directus_files_id?.id !== photoToBeDeletedId.current,
      );

      setTask((prev) => ({ ...prev, files: updatedTaskFiles }));

      // Update photos to be attached in storage
      const photosToBeAttached = getPhotosToBeAttachedFromStorage();
      const photosToBeAttachedUpdated = photosToBeAttached.filter(
        (item) => item.directus_files_id.id !== photoToBeDeletedId.current,
      );

      sessionStorage.setItem(
        "photosToBeAttached",
        JSON.stringify(photosToBeAttachedUpdated),
      );

      // Update selected task in storage
      const selectedTask = getSelectedTaskFromStorage();
      const updatedFiles = selectedTask.Files.filter(
        (item) => item.directus_files_id?.id !== photoToBeDeletedId.current,
      );
      const updatedTask = { ...selectedTask, Files: updatedFiles };

      sessionStorage.setItem("selectedTask", JSON.stringify(updatedTask));

      // Clear refs
      updatedTaskPhotosObjects.current = [];
      photoToBeDeletedId.current = "";

      // Close modal
      closeModal();

      // Set 'photoDeleted' flag in storage
      sessionStorage.setItem("photoDeleted", "true");

      // If photo is related to task, remove the relation
      if (taskRelationIdRef.current) {
        handleUnlinkPhotoFromTask(taskRelationIdRef.current);
      } else {
        showUploadPhotoSuccessAlert();
      }
    },
    onError: (error: AxiosError) => {
      closeModal();

      if (error.response?.status === 403) {
        notify(alert.warning.noPermissionToDeletePhoto, "warning");
      } else {
        notify(alert.error.failedToDletePhoto, "error");
      }
    },
  });

  // --------------- Photo handlers ---------------

  const handleAddPhoto = (photo: File) => {
    photoToBeUploadedRef.current = photo;

    const formData = new FormData();
    formData.append("photo", photo);
    formData.append("photoName", photo.name);

    const payload: UploadFilePayload = {
      token,
      formData,
      onUploadProgress,
    };

    uploadPhoto(payload);
  };

  const handleLastSlideSize = (imgSize: ImageSize) => {
    const sizeUnset = Object.values(lastSlideSize).some((size) => !size);

    if (sizeUnset) {
      setLastSlideSize(imgSize);
    }
  };

  const handleUnlinkPhotoFromTask = (taskRelationId: number) => {
    let payload: EditTaskPayload = {
      id: task.id,
      token,
      Files: {
        create: [],
        update: [],
        delete: [taskRelationId],
      },
    };

    unlinkPhotoFromTask(payload);
  };

  const handleDeletePhoto = () => {
    updatedTaskPhotosObjects.current = [...taskPhotosObjects];

    const { taskRelationId, photoId } = updatedTaskPhotosObjects.current.splice(
      currentSlide,
      1,
    )[0];

    photoToBeDeletedId.current = photoId;
    taskRelationIdRef.current = taskRelationId;

    deletePhotoFromServer({ token, fileIds: [photoId] });
  };

  // --------------- On click handlers ---------------

  const onDoneBtnClick = () => {
    const path = `/task/selected?id=${task.id}`;
    navigate(path, { replace: true });
  };

  const onBinIconClick = () => {
    openModal();
  };

  const onConfirmButtonClick = () => {
    handleDeletePhoto();
  };

  // --------------- On change handlers ---------------

  const onSlideChange = ({ activeIndex }: SwiperType) => {
    setSearchParams({ slide: `${activeIndex}` });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const photo = e.target.files[0];

      if (photo && photo.type.includes("image")) {
        handleAddPhoto(photo);
      } else {
        notify(alert.warning.notPhoto, "warning");
      }
    }
  };

  return (
    <ContentContainer>
      <Header isMobile={isMobile} marginTop noMarginBottom>
        {!taskPhotosObjects.length && (
          <BackArrow onClick={onDoneBtnClick} customClass='back-arrow' />
        )}
        <span className='header-title'>
          {header.title}&nbsp;&#8250;&nbsp;{header.photos}
        </span>
      </Header>

      <Container>
        <GalleryContainer isMobile={isMobile}>
          {!photoUploading && (
            <Swiper
              pagination={{
                type: "progressbar",
              }}
              navigation
              rewind
              modules={[Pagination, Navigation]}
              onSlideChange={onSlideChange}
              spaceBetween={10}
              initialSlide={currentSlide}
            >
              {taskPhotosObjects.map((item) => (
                <SwiperSlide key={`${item.src}`}>
                  <PreviewImage
                    src={item.src}
                    handleLastSlideSize={handleLastSlideSize}
                  />

                  <FullScreenIcon href={item.src} />
                </SwiperSlide>
              ))}

              <SwiperSlide>
                <LastSlide
                  size={lastSlideSize}
                  onChange={onChange}
                  photoUploading={photoUploading}
                  canTakePhotos={canTakePhotos}
                  labels={{
                    takePhoto: labels.btn.takeAnother,
                    selectExisting: labels.btn.selectExisting,
                  }}
                />
              </SwiperSlide>

              {!isLastSlide && (
                <PhotoCounter>
                  {currentSlide + 1}&nbsp;/&nbsp;
                  {taskPhotosObjects.length}
                </PhotoCounter>
              )}

              {!isLastSlide && (
                <BinContainer isMobile={isMobile} onClick={onBinIconClick}>
                  <BinIcon className='bin' />
                </BinContainer>
              )}
            </Swiper>
          )}

          {photoUploading && (
            <PhotoLoader
              progress={uploadProgress}
              photoFile={photoToBeUploadedRef.current}
            />
          )}
        </GalleryContainer>

        <Actions
          flexEnd={!canTakePhotos || (canTakePhotos && !!taskPhotosObjects.length)}
        >
          {!!taskPhotosObjects.length && (
            <Done
              isMobile={isMobile}
              onClick={onDoneBtnClick}
              role='button'
              disabled={photoUploading || deletingPhoto}
            >
              {labels.btn.done}
            </Done>
          )}
        </Actions>
      </Container>

      <ConfirmationModal
        message={modal.message.photo}
        onClick={onConfirmButtonClick}
        onClose={closeModal}
        open={open}
        buttonLabel={labels.btn.delete}
        loading={unlikingPhoto || deletingPhoto}
        closeDisabled={unlikingPhoto || deletingPhoto}
      />
    </ContentContainer>
  );
}

export default SelectedTaskPhotos;
